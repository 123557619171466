import {createStore} from "vuex";
import VuexPersistedstate from 'vuex-persistedstate'
import {changeTopSafeAreaColor, changeBottomSafeAreaColor} from "@/tarot_box/helper/native_api";
import {getSafeAreaColorCode, calculateOverLayColor} from "@/common/utils";

export default createStore({
    state: {
        user: null,
        inputData: [],
        myPageUserInfo: {
            displayName: '',
            cloverBalance: 0,
            referralCode: '',
            reminderTime: ''
        },
        tarot_boxes: [],  // 기존 tarotBoxes를 tarot_boxes로 변경
        promotion_banners: [],
        icon_categories: [],
        home_sections: [],
        ranking_subject_titles: [],
        feedbackBoxVisible: true,
        safeAreaColorTopHistory: ['#FFF9F9F9'],
        safeAreaColorBottomHistory: ['#FF171B2B'],
        tempInputData: [],
        tempCardData: "",
        paywall: {
            android: [],
            ios: []
        },
        subscriptionPaywall: {
            android: [],
            ios: []
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = {
                ...state.user,
                ...user,
            };
        },
        UPDATE_MY_PAGE_USER_INFO(state, payload) {
            state.myPageUserInfo = { ...state.myPageUserInfo, ...payload };
        },
        setTempInput(state, tempInputData) {
            state.tempInputData = tempInputData;
        },
        setTempCard(state, tempCardData) {
            state.tempCardData = tempCardData;
        },
        setTarotBoxes(state, tarot_boxes) {
            state.tarot_boxes = tarot_boxes;
        },
        setDailyTarotBox(state, dailyTarotBox) {
            state.dailyTarotBox = dailyTarotBox;
        },
        addDailyTarotBoxToTarotBoxes(state) {
            if (state.dailyTarotBox && !state.tarot_boxes.some(box => box.id === state.dailyTarotBox.id)) {
                state.tarot_boxes.push(state.dailyTarotBox);
            }
        },
        setPromotionBanners(state, promotion_banners) {
            state.promotion_banners = promotion_banners;
        },
        setIconCategories(state, icon_categories) {
            state.icon_categories = icon_categories;
        },
        setHomeSections(state, home_sections) {
            state.home_sections = home_sections;
        },
        setRankingSubjectTitles(state, ranking_subject_titles) {
            state.ranking_subject_titles = ranking_subject_titles;
        },
        initSafeAreaColorHistory(state) {
            state.safeAreaColorTopHistory = ['#FFF9F9F9'];
            state.safeAreaColorBottomHistory = ['#FF171B2B'];
            changeTopSafeAreaColor('#FFF9F9F9');
            changeBottomSafeAreaColor('#FF171B2B');
        },
        updateRecommendList(state, recommendList) {
            let section = state.home_sections.find(section => section.sectionType === "recommend");
            if (section) {
                section.tarotBoxIds = recommendList;
            }
        },
        setPaywall(state, paywall) {
            state.paywall = paywall;
        },
        setSubscriptionPaywall(state, subscriptionPaywall) {
            state.subscriptionPaywall = subscriptionPaywall;
        },
        setInputData(state, inputData) {
            state.inputData = inputData;
        },
    },
    actions: {
        setUserData(context, userInfo) {
            const user = {
                userId: userInfo.user_id,
                phoneNumber: userInfo.phone_number,
                userNickname: userInfo.user_nickname,
                webReferrer: userInfo.web_referrer,
            };
            context.commit('setUser', user);
        },
        updateMyPageUserInfo({ commit }, newUserInfo) {
            return new Promise((resolve) => {
                commit('UPDATE_MY_PAGE_USER_INFO', newUserInfo);
                resolve(true);
            });
        },
        setFeedbackBoxData({commit}, isVisible) {
            commit('setFeedbackBox', isVisible);
        },
        setTempInputData(context, tempInputData) {
            context.commit('setTempInput', tempInputData);
        },
        setTempCardData(context, tempCardData) {
            context.commit('setTempCard', tempCardData);
        },
        setTarotBoxesData(context, tarot_boxes) {
            const mappedTarotBoxes = tarot_boxes.map(box => ({
                id: box.id,
                title: box.title,
                subTitle: box.sub_title,
                category: box.category,
                tags: box.tags,
                tagImageUrl: box.tag_image_url,
                promotionImgUrl: box.promotion_img_url,
                thumbnailImgUrl: box.thumbnail_v2_img_url,
                categoryPriority: box.category_priority,
                description: box.description,
                tableOfContents: box.table_of_contents,
                inputPageUrl: box.input_page_url,
                rankingScore: box.ranking_score,
                originalPrice: box.original_price,
                salePrice: box.sale_price,
                priority: box.priority,
                boxType: box.box_type,
                appPermissions: box.app_permissions,
                screenShotImgUrlList: box.screen_shot_img_url_list,
                descImgUrl: box.desc_img_url,
                tarotSpread: box.tarot_spread,
                relatedBoxIds: box.related_box_ids,
                //231221 추가
                subjectColor: box.subject_color,
                subjectTitle: box.subject_title,
                //240112 추가
                feedbackSection: box.feedback_section, // 추가
                userReviews: box.user_reviews, // 추가
                reactionStats: box.reaction_stats, // 추가
                reviewReward: box.review_reward, // 추가
                reactionReward: box.reaction_reward, // 추가
                salePriceWon: box.sale_price_won, // 240219 추가
                originalPriceWon: box.original_price_won, // 240219 추가
                reviewCount: box.review_count // 240408 추가
            }));
            context.commit('setTarotBoxes', mappedTarotBoxes);
        },
        setDailyTarotBoxData(context, dailyTarotBox) {
            const mappedDailyTarotBox = {
                id: dailyTarotBox.id,
                title: dailyTarotBox.title,
                subTitle: dailyTarotBox.sub_title,
                category: dailyTarotBox.category,
                tags: dailyTarotBox.tags,
                tagImageUrl: dailyTarotBox.tag_image_url,
                promotionImgUrl: dailyTarotBox.promotion_img_url,
                thumbnailImgUrl: dailyTarotBox.thumbnail_img_url,
                thumbnailV2ImgUrl: dailyTarotBox.thumbnail_v2_img_url,
                categoryPriority: dailyTarotBox.category_priority,
                subjectTitle: dailyTarotBox.subject_title,
                subjectColor: dailyTarotBox.subject_color,
                description: dailyTarotBox.description,
                tableOfContents: dailyTarotBox.table_of_contents,
                inputPageUrl: dailyTarotBox.input_page_url,
                rankingScore: dailyTarotBox.ranking_score,
                reviewReward: dailyTarotBox.review_reward,
                reactionReward: dailyTarotBox.reaction_reward,
                originalPrice: dailyTarotBox.original_price,
                salePrice: dailyTarotBox.sale_price,
                priority: dailyTarotBox.priority,
                boxType: dailyTarotBox.box_type,
                originalPriceWon: dailyTarotBox.original_price_won,
                salePriceWon: dailyTarotBox.sale_price_won,
                appPermissions: dailyTarotBox.app_permissions,
                screenShotImgUrlList: dailyTarotBox.screen_shot_img_url_list,
                descImgUrl: dailyTarotBox.desc_img_url,
                tarotSpread: dailyTarotBox.tarot_spread,
                feedbackSection: dailyTarotBox.feedback_section
            };
            context.commit('setDailyTarotBox', mappedDailyTarotBox);
            context.commit('addDailyTarotBoxToTarotBoxes');
            context.commit('setTarotBoxes', [...context.state.tarot_boxes]);
            console.log('completed_up_daily_tarot_box')
        },
        setPromotionBannersData(context, promotion_banners) {
            const mappedPromotionBanners = promotion_banners.map(banner => ({
                id: banner.id,
                title: banner.title,
                imageUrl: banner.image_url,
                linkType: banner.link_type,
                linkUrl: banner.link_url,
                priority: banner.priority,
                status: banner.status,
                createdAt: banner.created_at,
                updatedAt: banner.updated_at,
            }));
            context.commit('setPromotionBanners', mappedPromotionBanners);
        },
        setIconCategoriesData(context, icon_categories) {
            const mappedIconCategories = icon_categories.map(category => ({
                id: category.id,
                title: category.title,
                linkType: category.link_type,
                tarotBoxCategories: category.tarot_box_categories,
                imageUrl: category.image_url,
                priority: category.priority,
                status: category.status,
                createdAt: category.created_at,
                updatedAt: category.updated_at,
                //231221 추가
                tarotBoxSubjectTitle: category.tarot_box_subject_title,
            }));
            context.commit('setIconCategories', mappedIconCategories);
        },
        setHomeSectionsData(context, home_sections) {
            const mappedHomeSections = home_sections.map(section => ({
                id: section.id,
                title: section.title,
                sectionType: section.section_type,
                tarotBoxIds: section.tarot_box_ids,
                priority: section.priority,
                status: section.status,
            }));
            context.commit('setHomeSections', mappedHomeSections);
        },
        setRankingSubjectTitlesData(context, ranking_subject_titles) {
            context.commit('setRankingSubjectTitles', ranking_subject_titles);
        },
        addSafeAreaColorTop(context, color) {
            if (color.startsWith('-')) {
                color = getSafeAreaColorCode(color);
            } else if (color === 'overlay') {
                color = calculateOverLayColor(context.state.safeAreaColorTopHistory[context.state.safeAreaColorTopHistory.length - 1]);
            }
            context.state.safeAreaColorTopHistory.push(color);
            const topColor = context.state.safeAreaColorTopHistory[context.state.safeAreaColorTopHistory.length - 1];
            changeTopSafeAreaColor(topColor);
        },
        addSafeAreaColorBottom(context, color) {
            if (color.startsWith('-')) {
                color = getSafeAreaColorCode(color);
            } else if (color === 'overlay') {
                color = calculateOverLayColor(context.state.safeAreaColorBottomHistory[context.state.safeAreaColorBottomHistory.length - 1]);
            }
            context.state.safeAreaColorBottomHistory.push(color);
            const bottomColor = context.state.safeAreaColorBottomHistory[context.state.safeAreaColorBottomHistory.length - 1];
            changeBottomSafeAreaColor(bottomColor);
        },
        previousSafeAreaColorTop(context) {
            if(context.state.safeAreaColorTopHistory.length <= 1) return;
            context.state.safeAreaColorTopHistory.pop();
            const topColor = context.state.safeAreaColorTopHistory[context.state.safeAreaColorTopHistory.length - 1];
            changeTopSafeAreaColor(topColor);
        },
        previousSafeAreaColorBottom(context) {
            if(context.state.safeAreaColorBottomHistory.length <= 1) return;
            context.state.safeAreaColorBottomHistory.pop();
            const bottomColor = context.state.safeAreaColorBottomHistory[context.state.safeAreaColorBottomHistory.length - 1];
            changeBottomSafeAreaColor(bottomColor);
        },
        updateRecommendList(context, recommendList) {
            context.commit('updateRecommendList', recommendList);
        },
        updatePaywall(context, paywall) {
            context.commit('setPaywall', paywall);
        },
        updateSubscriptionPaywall(context, subscriptionPaywall) {
            context.commit('setSubscriptionPaywall', subscriptionPaywall);
        },
        setInputData(context, inputData) {
            context.commit('setInputData', inputData);
        },
    },
    getters: {
        user: (state) => state.user,
        myPageUserInfo: state => state.myPageUserInfo,
        feedbackBoxVisible: (state) => state.feedbackBoxVisible,
        tarotBoxes: (state) => state.tarot_boxes,
        promotionBanners: (state) => state.promotion_banners,
        iconCategories: (state) => state.icon_categories,
        homeSections: (state) => state.home_sections,
        rankingSubjectTitles: (state) => state.ranking_subject_titles,
        safeAreaColorTopHistory: (state) => state.safeAreaColorTopHistory,
        safeAreaColorBottomHistory: (state) => state.safeAreaColorBottomHistory,
        hasTarotBoxes: state => state.tarot_boxes.length > 0,
        tempInputData: state => state.tempInputData,
        tempCardData: state => state.tempCardData,
        paywall: (state) => state.paywall,
        subscriptionPaywall: (state) => state.subscriptionPaywall,
        getSubscriptionPrices: (state) => (osType, avatarIndex) => {
            const platform = osType.toLowerCase() === 'ios' ? 'ios' : 'android';
            const group = avatarIndex % 4;

            const monthlyProduct = state.subscriptionPaywall[platform].find(
                product => product.group === group && product.period === 'monthly'
            );

            const yearlyProduct = state.subscriptionPaywall[platform].find(
                product => product.group === group && product.period === 'yearly'
            );

            return {
                monthly: monthlyProduct ? { price: monthlyProduct.price, product_id: monthlyProduct.product_id } : null,
                yearly: yearlyProduct ? { price: yearlyProduct.price, product_id: yearlyProduct.product_id } : null
            };
        },
        inputData: state => state.inputData,
    },

    plugins: [VuexPersistedstate(
    )]
});
