<template>
  <div class="survey-container" :class="{ 'collapsed': isCollapsed }" @click="toggleCollapse">
    <div class="survey-title" >
      <span class="gray6 title6">{{ inputItem.inputFormHeaderLabel }}</span>
      <span class="gray2 title3">{{ inputItem.inputFormQuestion }}</span>
    </div>

    <div class="survey-lists">
      <div v-for="(option, index) in scoreOptions" :key="index"
           class="survey-option"
           :class="{ 'selected': option.value === internalSelectedScore }">
        <span class="option-text text1 gray3">{{ option.displayText }}</span>
        <input
            type="radio"
            :id="`option-${inputItem.inputFormSubtype}-${index}`"
            :value="option.value"
            v-model="internalSelectedScore"
            @change="onRadioChange"
        >
        <label :for="`option-${inputItem.inputFormSubtype}-${index}`" class="custom-radio"></label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SurveyScore",
  props: {
    inputItem: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      internalSelectedScore: null,
      isCollapsed: false,
      animationInProgress: false
    }
  },
  computed: {
    scoreOptions() {
      const { scale, isAscending, displayTexts } = this.inputItem.options;
      return Array.from({ length: scale }, (_, index) => {
        const value = isAscending ? index : scale - 1 - index;
        return {
          value,
          displayText: displayTexts[index] || `옵션 ${index + 1}`
        };
      });
    },
    visibleOptions() {
      return this.isCollapsed ?
          this.scoreOptions.filter(option => option.value === this.internalSelectedScore) :
          this.scoreOptions;
    }
  },
  methods: {
    onRadioChange() {
      this.collapse();
    },
    initializeSelectedScore() {
      if (this.modelValue !== null && this.modelValue !== undefined) {
        this.internalSelectedScore = Number(this.modelValue);
        this.isCollapsed = true;
      } else {
        this.internalSelectedScore = null;
        this.isCollapsed = false;
      }
    },
    collapse() {
      if (!this.animationInProgress) {
        this.animationInProgress = true;
        this.isCollapsed = true;
        setTimeout(() => {
          this.animationInProgress = false;
          this.$emit('update:modelValue', this.internalSelectedScore);
          this.$emit('submitAnswer', {
            ...this.inputItem,
            value: this.internalSelectedScore,
            inputFormType: this.inputItem.inputFormType,
            inputFormSubtype: this.inputItem.inputFormSubtype
          });
        }, 500); // 애니메이션 duration과 맞춰주세요
      }
    },
    toggleCollapse() {
      if (this.internalSelectedScore !== null) {
        this.isCollapsed = !this.isCollapsed;
      }
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.initializeSelectedScore();
      }
    }
  },
  emits: ['update:modelValue', 'submitAnswer']
}
</script>

<style scoped>
.survey-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 2px solid var(--Grayscale-Gray10);
  margin-bottom: -16px;
  transition: all 0.4s ease;
  overflow: hidden;
}

.survey-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 6px;
  line-height: 1.2;
  margin-bottom: 12px;
}

.survey-lists {
  width: 100%;
  transition: max-height 0.4s ease;
  overflow: hidden;
}

.survey-container.collapsed .survey-lists {
}

.survey-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 10px 0;
  box-sizing: border-box;
  border-top: 1px solid var(--Grayscale-Gray10);
  transition: all 0.4s ease;
  max-height: 50px; /* Adjust this value based on your option height */
  opacity: 1;
}

.survey-container.collapsed .survey-option:not(.selected) {
  max-height: 0;
  opacity: 0;
  padding: 0;
  border-top: none;
}

.option-text {
  flex-grow: 1;
}

input[type="radio"] {
  display: none;
}

.custom-radio {
  width: 24px;
  height: 24px;
  border: 2px solid var(--Grayscale-Gray8);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

input[type="radio"]:checked + .custom-radio::after {
  content: '';
  width: 18px;
  height: 18px;
  background-color: var(--Color-palette-Primary-Default);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>