<template>
  <div class="confirm-popup-back-page">
    <div class="confirm-popup">
      <div class="confirm-popup-contents">
        <div class="confirm-popup-contents-title">
          {{ boxId === 320 ? "운세 결과를 확인해 볼까요?" : "Shall we check the results?" }}
        </div>
        <div class="confirm-popup-contents-delete">
          {{ boxId === 320 ? "오늘의 운세를 보면 클로버를 드려요!" : "MASTRO uses AI to learn precise interpretations and provides personalized results for you." }}
        </div>
      </div>

      <div class="price-tag-web">
        <span style="font-size: 16px; text-decoration: line-through; color:#999"> $28.00 </span>
        <span style="font-size: 20px; font-weight: 700; color: #2D3E73">$22.00</span>
      </div>
      <ShimmerImage
          :src="require('@/tarot_box/pages/box_input_v2/assets/icon.png')"
          :imageStyle="{ width: '60vw' }"
          :heightRatio="169/193"
          :isCircle="false"/>

      <!-- 웹 버전 버튼 -->
      <div v-web class="confirm-popup-button-area">
        <div class="confirm-popup-button" v-wave @click="goBackToWebMain()">
          LATER
        </div>
        <div class="confirm-popup-button" v-wave @click="$emit('paymentDone')">
          ORDER
        </div>
      </div>

      <!-- 앱 버전 버튼 -->
      <div v-app class="confirm-popup-button-area">
        <div class="confirm-popup-button" v-wave @click="skipResultView()">
          LATER
        </div>
        <div class="confirm-popup-button" v-wave @click="$emit('paymentDone')">
          ORDER
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {getTarotUserInfo, purchaseBox} from "@/tarot_box/helper/mytarot_api";
import {mapGetters, mapActions} from "vuex";
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";
import {logEvent} from "@/tarot_box/helper/native_api";
import {goToMyTarotTab} from "@/common/app_webview_handler";

export default {
  components: {ShimmerImage},
  computed: {
    ...mapGetters(['user', 'tarotBoxes']),
  },
  emits: ['paymentDone', 'update-user-info', 'close'],
  props: {
    paymentPopupData: {
      type: Object,
    },
    tarotBoxHistoryId: {
      type: Number,
    },
    item:{
      type: Object,
    },
    boxId: Number,
    inputData: String,
    pickedCards: String,
    tarotBoxData: Object,
    userId: Number,
    userPhoneNumber: String,
    historyId: Number,
    inputTarotData: Array,
  },
  data() {
    return {
      userInfo: {},
      boxPrice: 0,
      smallerPrice: '',
      biggerPrice: '',
      boxTitle: '',
      isCloverPaywallActive: false,
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions(['setTempInputData']),
    branchTitleByOsType() {
      let title;
      if (this.$isWeb()) {
        title = '결제하기';
      } else {
        title = this.paymentPopupData && this.paymentPopupData.title ? this.paymentPopupData.title : '결제하기';
      }
      return title;
    },
    addCommaToPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    goToMyTarotTab,
    async handleButtonFunction() {
      const apiResult = await getTarotUserInfo(this.userId, this.userPhoneNumber);
      this.userInfo = apiResult.data;

      if (this.userInfo.clover_balance < this.boxPrice) {
        let url = new URL(window.location.href);
        const buildNumber = parseInt(url.searchParams.get('build_number'));
        if(buildNumber >= 84){
          this.isCloverPaywallActive = true;
        } else {
          let dataToSend = {action: 'showPayWall'};
          window.sendInfoToApp(JSON.stringify(dataToSend));
        }
      } else {
        await purchaseBox(this.userId, this.userPhoneNumber, this.historyId);
        this.$emit('paymentDone');
      }
    },
    skipResultView() {
      logEvent('boxinput_purchase_notnow_click', {
        "tarot_box_id": this.boxId,
        "tarot_box_name": this.boxTitle,
        "price": parseInt(this.smallerPrice.replace(/,/g, '')),
      });
      this.$router.push('/tarot_box/home')
    },
    goBackToWebMain() {
      logEvent('boxinput_purchase_notnow_click', {
        "tarot_box_id": this.boxId,
        "tarot_box_name": this.boxTitle,
        "price": parseInt(this.smallerPrice.replace(/,/g, '')),
      });
      window.history.back();
    },
    goWebCheckout() {
      logEvent('boxinput_purchase_gogo_click', {
        "tarot_box_id": this.boxId,
        "tarot_box_name": this.boxTitle,
        "price": parseInt(this.smallerPrice.replace(/,/g, '')),
      });

      let parsedUserInputData;
      if (typeof this.inputData === 'string') {
        try {
          parsedUserInputData = JSON.parse(this.inputData);
        } catch (error) {
          console.error('Error parsing inputData:', error);
          // 에러 처리 로직
          return;
        }
      } else {
        parsedUserInputData = this.inputData;
      }

      const userInputParams = parsedUserInputData.map(item =>
          `${encodeURIComponent(item.key)}=${encodeURIComponent(item.value)}`
      ).join('&');
      const pickedCardsParam = this.inputTarotData ? `&picked_cards=${encodeURIComponent(this.inputTarotData)}` : '';
      this.$store.dispatch('setTempInputData', parsedUserInputData)
      this.$store.dispatch('setTempCardData', pickedCardsParam)

      const url = `/tarot_box/checkout/${this.boxId}?${userInputParams}${pickedCardsParam}&is_web=true`;
      this.$router.push(url);
    },
    logEvents() {
      logEvent ('boxinput_purchase_popup', {
        "tarot_box_id": this.boxId,
        "tarot_box_name": this.boxTitle,
      });
    },
    closePaywall() {
      this.$emit('update-user-info');
      this.isCloverPaywallActive = false;
    },
  },
}
</script>

<style scoped>
.confirm-popup-back-page{
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.80);
}

.confirm-popup{
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 40px;
  width: 90%;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  box-sizing: border-box;

  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
  z-index: 1001;
}

.confirm-popup-contents{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  gap: 12px;
}

.confirm-popup-contents-title{
  color: #000;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
}

.confirm-popup-contents-delete {
  text-align: center;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.confirm-popup-button-area {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 9px;
}

.confirm-popup-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  background: var(--Grayscale-Gray3);
  color: #FFF;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}

.confirm-popup-button:first-child {
  background: #E9E9E9;
  color: #6E6E6E;
}

.confirm-popup-button:last-child {
  background: var(--Grayscale-Gray3);
  color: #FFF;
}

.clover-icon {
  margin: 0 6px 0 4px;
}

.price-tag-web{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 6px;

  padding: 8px 22px;
  background: #F0F1F2;
  border-radius: 90px;
  margin-top: -20px;
  font-size: 14px;
  color: #747474;
}
</style>