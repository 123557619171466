export function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function newLine(string) {
    return string.replace('\\n', '\n');
}

export function camelToSnakeCase(obj) {
    if (Array.isArray(obj)) {
        return obj.map(item => camelToSnakeCase(item));
    } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            const snakeKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
            acc[snakeKey] = camelToSnakeCase(obj[key]);
            return acc;
        }, {});
    }
    return obj;
}

export function snakeToCamelCase(obj) {
    if (Array.isArray(obj)) {
        return obj.map(item => snakeToCamelCase(item));
    } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            const camelKey = key.replace(/(_\w)/g, match => match[1].toUpperCase());
            acc[camelKey] = snakeToCamelCase(obj[key]);
            return acc;
        }, {});
    }
    return obj;
}

export function getSafeAreaColorCode(colorName) {
    const rootStyle = getComputedStyle(document.documentElement);
    let color = rootStyle.getPropertyValue(colorName).trim();
    return '#FF' + color.replace("#", "");
}

function hexToRgb(hex) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
}

function rgbToHex(r, g, b) {
    let rh = r.toString(16).padStart(2, '0');
    let gh = g.toString(16).padStart(2, '0');
    let bh = b.toString(16).padStart(2, '0');
    return `#${rh}${gh}${bh}`;
}

export function calculateOverLayColor(hexColor) {
    if(hexColor.length === 9) {
        hexColor = hexColor.slice(0, 1) + hexColor.slice(3);
    }

    let [r, g, b] = hexToRgb(hexColor);
    let alpha = 0.8; // 80% 투명도

    r = Math.round(r * (1 - alpha));
    g = Math.round(g * (1 - alpha));
    b = Math.round(b * (1 - alpha));

    let overlayColor = rgbToHex(r, g, b);
    return '#FF' + overlayColor.replace("#", "");
}
