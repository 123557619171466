<template>
  <div class="city-search-input" v-safe-area-bottom>
    <div class="search-container">
      <input
          v-model="searchQuery"
          @input="searchCity"
          placeholder="Choose a location"
          class="search-input"
      />
    </div>
    <div class="search-results">
      <div
          v-for="city in searchResults"
          :key="city.id"
          class="city-item title4"
          @click="selectCity(city)"
          :class="{ 'selected': city.text === selectedCity }"
      >
        <span v-html="highlightText(city.text)"></span>
        <svg v-if="city.text === selectedCity" class="check-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" fill="white"/>
          <path d="M6 9L9.90476 14L14.6667 6" stroke="#333333" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

      </div>
    </div>
    <div class="confirm-button buttonL" @click="confirmSelectedCity" :class="{ 'disabled': !selectedCity }">
      DONE
    </div>
  </div>
</template>

<script>
import { searchCities } from "@/api/geocoding";

export default {
  name: 'CitySearchInput',
  data() {
    return {
      searchQuery: '',
      searchResults: [],
      selectedCity: null,
    }
  },
  methods: {
    async searchCity() {
      if (this.searchQuery.length > 1) {
        try {
          const response = await searchCities(this.searchQuery, 'en');
          this.searchResults = response.data.slice(0, 5); // 최대 5개의 결과만 표시
          if (!this.searchResults.some(city => city.text === this.selectedCity)) {
            this.selectedCity = null;
          }
        } catch (error) {
          console.error('Error searching cities:', error);
        }
      } else {
        this.searchResults = [];
      }
    },
    selectCity(city) {
      this.selectedCity = city.text;
      this.searchQuery = city.text;
    },
    confirmSelectedCity() {
      if (this.selectedCity) {
        this.$emit('select', {
          value: this.selectedCity,
          inputFormType: 'cityInput'
        });
      }
    },
    highlightText(text) {
      if (!this.searchQuery) return text;
      const regex = new RegExp(this.searchQuery, 'gi');
      return text.replace(regex, match => `<span style="color: var(--Color-palette-System-color-Blue); font-weight: bold;">${match}</span>`);
    }
  },
  emits: ['select']
}
</script>

<style scoped>
:deep(template){
  font-family: "SUIT-Variable", sans-serif;
}

input, .buttonL{
  font-family: "SUIT-Variable", sans-serif;
}

.city-search-input {
  margin-top: 20px;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
}

.search-container {
  margin-top: 70px;
  margin-bottom: 10px;
}

.search-input {
  width: 100%;
  font-size: 18px;
  padding: 14px 16px;
  height: 48px;
  border: 1px solid var(--Grayscale-Gray8);
  border-radius: 8px;
  box-sizing: border-box;
  font-family: "SUIT Variable", sans-serif;
  font-weight: 500;
}

.search-input input::placeholder {
  color: var(--Grayscale-Gray8);
  font-family: "SUIT Variable", sans-serif;
  font-weight: 500;
}

.search-results {
  min-height: 210px;
  overflow-y: auto;
}

.city-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 1px solid var(--Grayscale-Gray10);
  font-family: "SUIT Variable", sans-serif;
}

.city-item:last-child {
  border-bottom: none;
}

.confirm-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  padding: 16px 0;
  box-sizing: border-box;
  background: var(--Grayscale-Gray3);
  border-radius: 8px;
  color: var(--Grayscale-Gray12);
  cursor: pointer;
  font-family: "SUIT Variable", sans-serif;
}

.confirm-button:hover {
  background: var(--Grayscale-Gray4);
}

.confirm-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>