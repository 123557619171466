<script>
export default {
  name: "NatalChartSymbols",
  props: {
    sign: {
      type: String,
      required: true,
    },
  }
}
</script>

<template>
  <div v-show="sign === 'Sun'">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_178_310)">
        <path d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.0004 14.6003C13.4363 14.6003 14.6003 13.4363 14.6003 12.0004C14.6003 10.5644 13.4363 9.40039 12.0004 9.40039C10.5644 9.40039 9.40039 10.5644 9.40039 12.0004C9.40039 13.4363 10.5644 14.6003 12.0004 14.6003Z" fill="#121212"/>
      </g>
      <defs>
        <clipPath id="clip0_178_310">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <div v-show="sign === 'Moon'">
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6352 0.5C6.37237 0.5 2.62868 2.72321 0.5 6.073C1.8708 5.01418 3.5906 4.38393 5.45693 4.38393C9.93881 4.38393 13.573 8.01733 13.573 12.5C13.573 16.9827 9.9396 20.6161 5.45693 20.6161C3.5906 20.6161 1.8708 19.9858 0.5 18.927C2.62868 22.2768 6.37237 24.5 10.6352 24.5C17.2623 24.5 22.6345 19.1279 22.6345 12.5008C22.6345 5.87369 17.2623 0.5 10.6352 0.5Z" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>

  <div v-show="sign === 'Mercury'">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5018 14.4082C17.6193 12.2907 17.6193 8.8577 15.5018 6.74027C13.3844 4.62284 9.95138 4.62284 7.83395 6.74027C5.71652 8.8577 5.71652 12.2907 7.83395 14.4082C9.95138 16.5256 13.3844 16.5256 15.5018 14.4082Z" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.39551 1C6.96679 3.38167 9.1108 5.15206 11.6679 5.15206C14.2249 5.15206 16.3683 3.38167 16.9402 1" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.668 15.9951V22.9996" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.52051 19.3291H14.8149" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>

  <div v-show="sign === 'Venus'">
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.291 15.5819C16.3176 15.5819 19.5819 12.3176 19.5819 8.29095C19.5819 4.26427 16.3176 1 12.291 1C8.26427 1 5 4.26427 5 8.29095C5 12.3176 8.26427 15.5819 12.291 15.5819Z" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.291 15.5811V24.9999" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.05859 20.0645H16.5226" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>

  <div v-show="sign === 'Earth'">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2_7527)">
        <path d="M11.9996 22.9993C18.0746 22.9993 22.9993 18.0746 22.9993 11.9996C22.9993 5.92471 18.0746 1 11.9996 1C5.92471 1 1 5.92471 1 11.9996C1 18.0746 5.92471 22.9993 11.9996 22.9993Z" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 1V23" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23 12H1" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2_7527">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <div v-show="sign === 'Mars'">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_7435)">
        <path d="M9.39507 23.0001C14.0315 23.0001 17.7901 19.2415 17.7901 14.605C17.7901 9.96856 14.0315 6.20996 9.39507 6.20996C4.7586 6.20996 1 9.96856 1 14.605C1 19.2415 4.7586 23.0001 9.39507 23.0001Z" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.3311 8.66887L22.999 1" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.9934 8.46421V1.00586H15.5342" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1_7435">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <div v-show="sign === 'Ceres'">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.1943 14.4443V22.9998" stroke="#121212" stroke-linecap="round"/>
      <path d="M6 18.7217H16.3887" stroke="#121212" stroke-linecap="round"/>
      <path d="M11.234 14.4443C14.7559 14.4443 17.611 11.4347 17.611 7.72213C17.611 4.0096 14.7559 1 11.234 1C9.06693 1 7.15236 2.13942 6 3.88091" stroke="#121212" stroke-linecap="round"/>
    </svg>
  </div>

  <div v-show="sign === 'Jupiter'">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_7459)">
        <path d="M4 18.4834C8.17474 17.482 11.278 13.7239 11.278 9.24171C11.278 4.75954 8.17474 1.00138 4 0" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.19043 18.4834H19.4424" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.0459 24.0005V9.24219" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1_7459">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <div v-show="sign === 'Saturn'">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.00195 1V20.3747" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5 5.63672H13.0038" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.04785 11.3142C9.04785 11.3142 13.8532 7.73288 16.4858 10.9161C19.4133 14.456 14.6189 19.5476 15.5676 21.8431C16.4568 23.9934 19.1489 22.486 19.1489 22.486" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>

  <div v-show="sign === 'Uranus'">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8625 23.0004C14.7351 23.0004 17.0639 20.6717 17.0639 17.799C17.0639 14.9264 14.7351 12.5977 11.8625 12.5977C8.98987 12.5977 6.66113 14.9264 6.66113 17.799C6.66113 20.6717 8.98987 23.0004 11.8625 23.0004Z" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.8945 12.4482V1" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.7897 1.00879H16.5332V10.5816H19.7897" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 10.5816H7.25652V1.00879H4" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.1961 5.79492H7.59375" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>

  <div v-show="sign === 'Neptune'">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.25 0.857422V23.1429" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.03906 15.7988H18.4604" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1.8457 0.857422C2.97307 5.62267 7.20408 9.16489 12.2502 9.16489C17.2963 9.16489 21.5262 5.62267 22.6547 0.857422" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>

  <div v-show="sign === 'Pluto'">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_7403)">
        <path d="M17.7494 7.06168C18.2273 4.11735 16.2279 1.34306 13.2836 0.865132C10.3393 0.3872 7.56498 2.38661 7.08705 5.33094C6.60912 8.27526 8.60853 11.0495 11.5529 11.5275C14.4972 12.0054 17.2715 10.006 17.7494 7.06168Z" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.418 14.4336V24.2879" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.99023 19.124H16.8455" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 8.61719C5.80371 11.9679 8.82005 14.4586 12.4175 14.4586C16.0149 14.4586 19.0304 11.9679 19.8349 8.61719" stroke="#121212" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1_7403">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>

  <div v-show="sign === 'Ascendant'">
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9775 23.061L11.9775 1.42969" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.7712 8.22298L11.9773 1.42969L5.18457 8.22298" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>




</template>

<style scoped>
svg{
  height: 24px;
}

</style>