<template>
  <div class="item-footer">

    <div class="extra-cta"
        v-if="item.extra_question_status !== 'inactive' && item.status !== 'requested'  && item.tags !== 'new' && item.status !== 'paid_and_waiting'"
        @click="$emit('click-extra')">
      <div class="texts">
        <div class="subtext"
              v-if="item.extra_question_status === 'active' || item.extra_question_status === 'processing'">
          <span id="sub">전문가 상담 그대로! 궁금증은 남지 않도록!</span>
        </div>
        <div class="text">
          <img v-if="item.extra_question_status === 'new'"
              src="@/tarot_box/pages/my_tarot/assets/images/new_big_badge.svg">
          <span id="main">{{getextraQuestion(item.extra_question_status)}}</span>
        </div>
      </div>
      <div class="ctas">
        <LoaderImage color="#242424"
                     v-if="item.extra_question_status === 'processing'"
                     :size="12"
                     :speed="0.6"
                     :borderWidth="10" />
        <img v-else src="@/tarot_box/pages/my_tarot/assets/images/go_arrow.svg">
      </div>

    </div >

    <div class="cta"
         @click="$emit('click-review')"
         v-if="item.status !== 'paid_and_waiting' && shouldDisplayItemFooter"
         :class="{ 'buy-now': item.status === 'requested' }">
      <span class="buttonL"
            :class="{ 'buy-now': item.status === 'requested' }"
            v-html="getButtonContent(item.status)"/>
      <img
            v-if="item.status === 'requested'"
            style="fill: var(--Grayscale-Gray12)"
            src="@/tarot_box/pages/my_tarot/assets/images/go_arrow_white.svg">
      <span class="text3"
            v-if="item.status == 'finished'&& item.feedback_reward_status === 'available'">
        <strong>{{calculateRemainingDays(item.updated_at)}}일 남음</strong>
      </span>
    </div>
  </div>
</template>

<script>
import LoaderImage from "@/tarot_box/components/LoaderImage.vue";

export default {
  name: "MyTarotItemFooter",
  components: {
    LoaderImage
  },
  data() {
    return {
      isReviewExpired: false,
      cloverIconSvg: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin: 0 2px 0 2px">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.96081 9.00001C8.97394 8.98559 8.98698 8.9711 8.99995 8.95653C9.01292 8.9711 9.02596 8.98559 9.03909 9C9.02596 9.01442 9.01292 9.02891 8.99995 9.04348C8.98698 9.02891 8.97394 9.01442 8.96081 9.00001ZM8.99995 16.2693C8.05271 17.3332 6.69276 18 5.18176 18C2.31975 18 -0.000366211 15.6076 -0.000366211 12.6564C-0.000366211 11.242 0.532582 9.95587 1.40271 9.00001C0.532582 8.04415 -0.000366211 6.75806 -0.000366211 5.34361C-0.000366211 2.39242 2.31975 0 5.18176 0C6.69276 0 8.05271 0.666842 8.99995 1.73069C9.94719 0.666842 11.3071 0 12.8181 0C15.6801 0 18.0003 2.39242 18.0003 5.34361C18.0003 6.75806 17.4673 8.04415 16.5972 9.00001C17.4673 9.95587 18.0003 11.242 18.0003 12.6564C18.0003 15.6076 15.6801 18 12.8181 18C11.3071 18 9.94719 17.3332 8.99995 16.2693Z" fill="#4BD1A9"/>
          <path d="M9.4178 13.1878C8.85617 13.1878 8.33054 13.0906 7.84091 12.8962C7.35128 12.6945 6.92285 12.4173 6.55563 12.0645C6.19561 11.7117 5.91119 11.3013 5.70238 10.8332C5.50077 10.358 5.39996 9.84678 5.39996 9.29954C5.39996 8.74511 5.50077 8.23388 5.70238 7.76585C5.91119 7.29062 6.19921 6.8766 6.56643 6.52378C6.93365 6.17096 7.35848 5.89734 7.84091 5.70293C8.33054 5.50132 8.85617 5.40051 9.4178 5.40051C9.83543 5.40051 10.2387 5.45812 10.6275 5.57332C11.0163 5.68853 11.3763 5.85054 11.7075 6.05935C12.046 6.26816 12.334 6.52018 12.5716 6.8154L11.5131 7.86306C11.2395 7.52464 10.9227 7.26902 10.5627 7.09621C10.2098 6.9234 9.82823 6.837 9.4178 6.837C9.07938 6.837 8.76256 6.9018 8.46735 7.03141C8.17213 7.15382 7.91651 7.32663 7.7005 7.54984C7.48449 7.76585 7.31528 8.02507 7.19287 8.32749C7.07046 8.6227 7.00926 8.94672 7.00926 9.29954C7.00926 9.64516 7.07046 9.96918 7.19287 10.2716C7.31528 10.5668 7.48809 10.826 7.7113 11.0492C7.93451 11.2653 8.19373 11.4345 8.48895 11.5569C8.79136 11.6793 9.11898 11.7405 9.47181 11.7405C9.86783 11.7405 10.2387 11.6577 10.5843 11.4921C10.9299 11.3265 11.2359 11.0889 11.5023 10.7792L12.5284 11.7945C12.2908 12.0825 12.0064 12.3309 11.6751 12.5397C11.3439 12.7485 10.9839 12.9106 10.5951 13.0258C10.2134 13.1338 9.82103 13.1878 9.4178 13.1878Z" fill="#121212"/>
        </svg>`,
      isQuestionable: 'yes'
    }
  },
  props: {
    item: {
      type: Object,
    }
  },
  emits: ['click-review', 'click-extra'],
  computed: {
    shouldDisplayItemFooter() {
      if (this.item.status === 'requested') {
        return true;
      }
      return this.item.status !== 'paid_and_waiting' &&
          !this.isReviewExpired &&
          !(this.isReviewExpired === false && this.item.feedback_reward_status === 'unavailable');
    },
  },
  methods: {
    getextraQuestion(extraQuestionStatus){
      switch(extraQuestionStatus){
        case 'active':
          return '궁금한 점 심층풀이 받기';
        case 'processing':
          return'질문하신 고민 심층풀이 중';
        case 'new':
          return '추가질문 심층풀이 완료'
        default:
          return '추가질문 심층풀이 확인';
      }
    },
    getButtonContent(status) {
      switch(status) {
        case 'finished':
          return `리뷰쓰고 ${this.item.feedback_reward} ${this.cloverIconSvg} 받기`;
        case 'requested':
          return `${this.item.price} ${this.cloverIconSvg}로 결과보기`;
        default:
          return `무료로 운세보기`;
      }
    },
    calculateRemainingDays(days) {
      const now = new Date();
      const createdAt = new Date(days);
      const diff = 14 - Math.ceil((now.getTime() - createdAt.getTime())  / (1000 * 3600 * 24));

      if(diff > 0){
        return diff
      } else {
        this.isReviewExpired = true;
        this.isReviewExpired = true;
        return diff
      }
    },
  }
}
</script>

<style scoped>

.item-footer{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  gap: 16px;
  margin-top: 16px;
}

.cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 18px;
  border-radius: 8px;
  border: 1px solid var(--Color-palette-Primary-Default, #1E2352);
  background: var(--Grayscale-Gray12, #FFF);
}

svg{
  font-size: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  margin-right: 8px;
}

.cta.buy-now{
  background: var(--Color-palette-Primary-Default, #1E2352);
}

.buttonL{
  display: flex;
  flex-direction: row;
}

.buttonL.buy-now{
  color: var(--Grayscale-Gray12);
}

.text3{
  color: var(--Grayscale-Gray7);
}

.extra-cta{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 13px 18px;
  border-radius: 8px;
  color: var(--Grayscale-Gray2);
  background: #E5EAF2;
}

.texts{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.text{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.subtext{
display: flex;
  flex-direction: row;
  align-items: center;
}


#sub{
  color: var(--Grayscale-Gray4, #555);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

#main{
  color: var(--Grayscale-Gray2, #242424);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}


</style>