export function isNewAppVersion() {
    const urlParams = new URLSearchParams(window.location.search);
    const buildNumber = parseInt(urlParams.get('build_number'));
    return !isNaN(buildNumber) && buildNumber >= 83;
}

export function isAOS() {
    const urlParams = new URLSearchParams(window.location.search);
    const osType = urlParams.get('os_type');
    return osType === 'aos';
}