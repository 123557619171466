import {map, Subject, take} from "rxjs";
import {filter} from "rxjs/operators";
import {snakeToCamelCase} from "@/common/utils";

class AppListener {
    actionSubject = new Subject();

    // `controller.runJavaScript('appListener.sendAction("$actionString")')` from flutter
    // {"action": "goToHistoryTab", "data": {}}
    sendAction(actionString) {
        this.actionSubject.next(actionString);
    }

    // const subscription = appListener.subscribeAction((actionName, actionString) => alert(actionString))
    // subscription.unsubscribe()
    subscribeAction(actionName, callback) {
        return this.actionSubject.pipe(
            map(this.#parseAction),
            filter(this.#filterAction(actionName)),
            map(this.#extractData),
        ).subscribe({
            next: callback,
        });
    }

    subscribeSingleAction(actionName, callback) {
        return this.actionSubject.pipe(
            map(this.#parseAction),
            filter(this.#filterAction(actionName)),
            map(this.#extractData),
            take(1),
        ).subscribe({
            next: callback,
        });
    }

    #filterAction(actionName) {
        return (action)=> {
            return action.action === actionName
        }
    }

    #extractData(object) {
        return object.data || {}
    }

    #parseAction(actionString) {
        try {
            return snakeToCamelCase(JSON.parse(actionString))
        } catch (e) {
            return {}
        }
    }
}

const appListener = new AppListener();
const AppListenerPlugin = {
    install: (app) => {
        window.appListener = appListener;
        app.provide('appListener', appListener);
    }
}

export default AppListenerPlugin;
