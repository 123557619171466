<template>
  <div class="transaction-container">
      <div class="transaction-contents">
      <div class="title-area">
        <div class="badge">
          <img @load="logEvents" src="@/tarot_box/pages/box_input_v2/assets/sparkle.svg" alt="sparkle"/>
          <span class="title4" style="color: white;">맞춤형 풀이 준비 완료!</span>
        </div>
        <div class="title-text">
          <span class="title">결과를 확인해볼까요?</span>
          <span class="subTitle">나의 성향과 상황에 딱 맞춘 해석을 확인해보세요.</span>
        </div>
      </div>
        <div class="review-area">
          <ShimmerImage
              v-if="currentBoxData || currentBoxData?.promotionImgUrl"
              :src="currentBoxData?.promotionImgUrl"
              :imageStyle="{ width: '35vh' }"
              :border-radius="'8px'"
              :heightRatio="71/90"
          />
            <ShimmerImage
              v-else
              :src="require('@/tarot_box/pages/box_input_v2/assets/transaction-graphic.png')"
              :imageStyle="{ width: '30vh' }"
              :heightRatio="262/415"
              />

          <div v-if="filteredReviews.length > 0" class="review-item-container" ref="reviewItemContainer" :style="containerStyle">
            <div class="review-item-container" ref="reviewItemContainer">
              <div class="review-item" v-for="review in duplicatedReviews" :key="review.id">
                <div class="emoji">{{ getEmoji(review.user_reaction) }}</div>
                <div class="item-container">
                  <span class="title3">{{ review.user_reaction }}</span>
                  <span class="gray5 text2">{{ formatReview(review.user_review) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    <div class="cta-area" v-safe-area-bottom>
      <div class="button-area">
        <div v-wave class="proceed-button-white" @click="clickLater">다음에 보기</div>
        <div v-wave v-web class="proceed-button" @click="clickWebCheckout">
          <span v-if="tarotBoxData.originalPriceWon" class="original-price">{{ addComma(tarotBoxData?.originalPriceWon) }}원</span>
          {{ addComma(tarotBoxData.salePriceWon) }}원 결제
        </div>
        <div v-wave v-app class="proceed-button" @click="clickAppCheckout">
          {{tarotBoxData.salePrice}}
          <img :src="require('@/tarot_box/pages/box_input_v2/assets/clover.svg')" alt="clover"/>
          로 결과보기</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "InputTransaction",
  components: {ShimmerImage},
  props: {
    boxId: {
      type: Number,
      required: true
    },
    tarotBoxData: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      defaultImage: require('@/tarot_box/pages/box_input_v2/assets/transaction-graphic.png'),
    }
  },
  emits: ['checkout', 'later' ,'checkout-web', 'checkout-app'],
  mounted() {
    this.$nextTick(() => {
      this.setupAnimation();
      window.addEventListener('resize', this.setupAnimation);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setupAnimation);
  },
  computed: {
    ...mapGetters(['tarotBoxes']),
    currentBoxData() {
      return this.tarotBoxes.find(box => box.id === this.boxId);
    },
    filteredReviews() {
      if (!this.currentBoxData || !this.currentBoxData.userReviews) return [];
      return this.currentBoxData.userReviews
          .filter(review => ['도움 돼요', '재밌어요'].includes(review.user_reaction))
          .slice(0, 10);
    },
    duplicatedReviews() {
      // 리뷰를 두 번 복제하여 연속적인 스크롤 효과를 만듭니다
      return [...this.filteredReviews, ...this.filteredReviews, ...this.filteredReviews, ...this.filteredReviews, ...this.filteredReviews, ...this.filteredReviews, ...this.filteredReviews];
    },
    animationDuration() {
      const baseSpeed = 7; // 기본 속도 (초 단위)
      const reviewCount = Math.max(this.filteredReviews.length, 3); // 최소 1로 설정
      return reviewCount * baseSpeed;
    },
    containerStyle() {
      return {
        display: 'flex',
        animation: `scroll ${this.animationDuration}s linear infinite`,
        transform: `translateX(-${this.scrollDistance}px)`
      };
    },
    scrollDistance() {
      const itemContainer = this.$refs.reviewItemContainer;
      return itemContainer ? itemContainer.scrollWidth / 4 : 0;
    }
  },
  watch: {
    filteredReviews() {
      this.$nextTick(() => {
        this.setupAnimation();
      });
    }
  },
  methods: {
    getEmoji(reaction) {
      return reaction === '도움 돼요' ? '🙏' : '👍';
    },
    addComma(price) {
      if (typeof price !== 'number' && typeof price !== 'string') {
        console.warn('Invalid price value:', price);
        return '0';
      }
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    formatReview(review) {
      return review.replace(/\n/g, ' ');
    },
    setupAnimation() {
      const itemContainer = this.$refs.reviewItemContainer;
      if (!itemContainer) return;

      const containerWidth = itemContainer.scrollWidth;
      const viewportWidth = itemContainer.offsetWidth;

      // 애니메이션 키프레임 동적 생성
      const keyframes = `
      @keyframes scroll {
        0% { transform: translateX(40%); }
        100% { transform: translateX(${-containerWidth + viewportWidth}px); }
      }
    `;

      // 스타일 요소 생성 및 추가
      const styleElement = document.createElement('style');
      styleElement.textContent = keyframes;
      document.head.appendChild(styleElement);

      // 인라인 스타일 적용
      itemContainer.style.animation = `scroll ${this.animationDuration}s linear infinite`;

      this.$forceUpdate();
    },
    logEvents(){
      logEvent('boxinput_purchase_into',{
        'tarot_box_id': this.boxId,
        'tarot_box_name': this.currentBoxData?.name,
      })
    },
    clickLater(){
      logEvent('boxinput_purchase_notnow_click',{
        'tarot_box_id': this.boxId,
        'tarot_box_name': this.currentBoxData?.name,
      })
      this.$emit('later');
    },
    clickWebCheckout(){
      logEvent('boxinput_purchase_gogo_click',{
        'tarot_box_id': this.boxId,
        'tarot_box_name': this.currentBoxData?.name,
      })
      this.$emit('checkout-web');
    },
    clickAppCheckout(){
      logEvent('boxinput_purchase_gogo_click',{
        'tarot_box_id': this.boxId,
        'tarot_box_name': this.currentBoxData?.name,
      })
      this.$emit('checkout-app');
    }
  }
}
</script>

<style scoped>
.transaction-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 480px;
  height: 100%;
  background-color: white;
  padding: 24px 0 56px 0;
  box-sizing: border-box;
}

.transaction-contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
}

.title-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 28px;
  gap: 16px;
}

@keyframes gradientFlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 300% 50%;
  }
}

.badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 14px;
  border-radius: 50px;
  gap: 4px;
  background: linear-gradient(10deg, #2E89F2, #363DD6, #2E89F2, #363DD6);
  background-size: 300% 100%;
  animation: gradientFlow 6s linear infinite;
}

.title-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: var(--Grayscale-Gray3);
}

.subTitle{
  font-size: 16px;
  font-weight: 400;
  color: var(--Grayscale-Gray5);
  text-align: left;
}

.review-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 68px;
  box-sizing: border-box;
  position: relative;
}

.review-items{
  width: 100%;
}

.review-item-container {
  display: flex;
}

.review-item {
  flex: 0 0 280px;
  margin-right: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  width: 280px;
  padding: 28px 20px;
  box-sizing: border-box;
  border-radius: 8px;
  background: white;
  box-shadow: 0 0 16px 0 rgba(30, 35, 82, 0.12);
}

@keyframes scroll {
  0% {
    transform: translateX(40%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.item-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  gap: 6px;
}

.item-container .text2 {
  width: 240px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  line-height: 1.5em;
  max-height: 3em; /* line-height의 2배 */
}

.emoji{
  position: absolute;
  top: -25px;
  left: 20px;
  font-size: 36px;
  filter: drop-shadow(0px 4px 4px rgba(255, 255, 255, 1));

}



.cta-area {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  width: 100%;
}

.button-area{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px 4px 24px;
  height: 80px;
  box-sizing: border-box;
  background-color: white;
  gap: 8px;
  z-index: 11;
}

.proceed-button {
  display: flex;
  flex-direction: row;
  flex: 5;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 16px;
  background: var(--Color-palette-Primary-Default);
  color: white;
  font-size: 18px;
  font-weight: 700;
}

.proceed-button img{
  margin: 0 4px 0 2px;
}

.proceed-button-white {
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  background: var(--Grayscale-Gray12);
  color: var(--Color-palette-Primary-Default);
  outline: 2px solid var(--Color-palette-Primary-Default);
  font-size: 18px;
  font-weight: 700;
}

.original-price{
  font-size: 14px;
  margin-right: 6px;
  text-decoration: line-through;
  color: var(--Color-palette-Primary-Lighten3);
}

.discount-price{
  color: var(--Color-palette-Primary-Default);
  font-size: 18px;
  font-weight: 700;
}

</style>