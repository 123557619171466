<template>
  <div class="screen">
    <div class="cta">
      <button @click="goInputPage">GET MY READING
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.666 16.21L6.98 15.524L11.474 11.016L6.98 6.522L7.666 5.822L12.86 11.016L7.666 16.21Z" fill="#333333"/>
        </svg>
      </button>
    </div>
    <div class="sections-container">
      <div class="section">
        <img src="https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mastro%2FA-min.jpg?alt=media&token=c5034d01-48a4-48f4-a4ea-3d6c320b7e0c" alt="A-min" />
        <img src="https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mastro%2Ffooter-min.jpg?alt=media&token=d0e2180f-232b-4185-a89a-23131b2a4d2c" alt="footer-min" />
        <img src="https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/mastro%2F%EC%9C%A0%EC%9D%98%EC%82%AC%ED%95%AD-min.jpg?alt=media&token=92f80a90-a347-4456-9748-de48b3b46df2" alt="유의사항-min" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getTarotBoxItemsByFetch } from "@/tarot_box/helper/mytarot_api";

export default {
  name: "HomePage",
  data() {
    return {
      utm: null,
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.utm = urlParams.get('utm_medium');
    this.refreshItems();
  },
  methods: {
  ...mapActions(['setUserData', 'setTarotBoxesData', 'setRankingSubjectTitlesData','setPromotionBannersData', 'setIconCategoriesData', 'setHomeSectionsData', 'updateRecommendList','addSafeAreaColorTop', 'addSafeAreaColorBottom', 'previousSafeAreaColorTop', 'previousSafeAreaColorBottom']),
    async refreshItems() {
      try {
        let isWeb = this.$isWeb();
        let res = await getTarotBoxItemsByFetch("01032382218", 121904, isWeb);
        if (res.result === 'success'){
          this.setTarotBoxesData(res.data.tarot_boxes);
          this.setPromotionBannersData(res.data.promotion_banners);
          this.setIconCategoriesData(res.data.icon_categories);
          this.setHomeSectionsData(res.data.home_sections);
          this.setRankingSubjectTitlesData(res.data.ranking_subject_titles);
          this.totalCarousel = res.data.promotion_banners.length;
        }
      } catch (error){
        console.error("Error in refreshItems:", error);
      }
    },
    goInputPage() {
      this.$router.push('/tarot_box/input_v2/325?os_type=web');
    },
  },
}
</script>

<style scoped>
.screen {
  position: relative;
  width: 100%;
  max-width: 480px;
  height: 100vh;
  overflow-y: auto;
  margin: 0 auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.screen::-webkit-scrollbar {
  display: none;
}

.sections-container {
  display: flex;
  flex-direction: column;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: #2e2e2e;
  padding-bottom: 52px;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

.cta{
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 480px;
  padding: 12px 20px;
  background: #000000;
  display: flex;
  justify-content: center;
  height: 66px;
  box-sizing: border-box;
  border: none;
}

button{
  width: 100%;
  height: 100%;
  background: #FFF3DB;
  color: #000000;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>