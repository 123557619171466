<template>
  <div class="image-container">
    <div v-if="!imageLoaded" class="shimmer-box" :style="localImageStyle"></div>
    <img :src="src" alt="shimmer-image" v-show="imageLoaded" @load="handleImageLoad" :style="localImageStyle"/>
  </div>
</template>

<script>
export default {
  name: "ShimmerIcon",
  props: {
    src: {
      type: String,
      required: true,
    },
    imageStyle: {
      type: Object,
      default: () => ({}),
    },
    heightRatio: {
      type: Number,
      default: 1,
    },
    isCircle: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: '0px',
    },
  },
  data() {
    return {
      imageLoaded: false,
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    localImageStyle() {
      let style = { ...this.imageStyle };
      style.boxSizing = 'border-box';
      style.maxWidth = '480px';

      if (this.borderRadius) {
        style.borderRadius = this.borderRadius;
      }
      if (style.width && style.width.includes('vw')) {
        const screenWidth = window.innerWidth;
        const widthVW = parseFloat(style.width);
        const widthPX = screenWidth * (widthVW / 100);
        const height = widthPX * this.heightRatio;

        style.height = `${height}px`;
      } else if (style.width) {
        const width = parseInt(style.width);
        const unit = style.width.match(/\D+$/)[0];
        const height = width * this.heightRatio;

        if (unit === 'vw') {
          style.height = height + unit;
        } else if (unit === 'rem') {
          style.height = height + unit;
        } else {
          style.height = `${height}${unit}`;
        }
      }

      // 조건을 추가하여 이미 존재하는 경우 maxWidth와 maxHeight를 설정하지 않음
      if (!this.imageStyle.maxWidth) {
        style.maxWidth = style.width ? `${parseFloat(style.width) * 4.8}${style.width.match(/\D+$/)[0]}` : null;
      }
      if (!this.imageStyle.maxHeight) {
        style.maxHeight = style.height ? `${parseFloat(style.height) * 4.8}${style.height.match(/\D+$/)[0]}` : null;
      }

      return style;
    }
  },
  methods: {
    handleImageLoad(){
      this.imageLoaded = true;
    },
    handleResize() {
      this.$forceUpdate();
    }
  },
}
</script>


<style scoped>
.shimmer-box {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #F9F7F7; /* #FFFFFF로 변경하여 전체 배경을 흰색으로 설정 */
  animation-duration: 1s; /* 깜빡거리는 주기를 1초로 설정 */
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background-image: linear-gradient(
      90deg,
      rgba(240, 240, 240, 1) 0%,
      rgba(255, 255, 255, 0.7) 25%,
      rgba(250, 250, 250, 1) 50%,
      rgba(255, 255, 255, 0.7) 75%,
      rgba(240, 240, 240, 1) 100%
  );
  background-size: 200% 200%;
  box-sizing: border-box;
}

.image-container{
  font-size: 0;
}

@keyframes shimmer {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

</style>
