<template>
  <div class="component-container"
       :style="{
      padding: padding,
      backgroundImage: `url(${tarotBoxHistory.output_page_meta_data.background_image_url})`,
      backgroundColor: backgroundColor}">
    <div class="content-wrapper">
      <div class="heading-texts">
        <img id="symbol" :src="tarotBoxHistory.output_page_meta_data.objet_icon_image_url" alt="symbol"/>
        <span class="font14 gray5">
          <strong>Appendix</strong>
        </span>
        <span class="serif gray1 font20">Get Personalized Answers</span>
      </div>
      <div class="extra-listings">
        <div class="content-texts serif gray4 questions">
          <span style="margin-bottom: 12px;"><strong>My questions</strong></span>
          {{currentPageData.page_content.extra_question}}
        </div>
        <div class="content-texts serif gray4 questions">
          <span style="margin-bottom: 12px;"><strong>Interpretation</strong></span>
          <div v-html="formattedContent"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { marked } from 'marked';
import {markReadExtraAnswer} from "@/tarot_box/helper/mytarot_api";
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "ExtraAnswerComponent",
  props: {
    currentIndex: {
      type: Number,
    },
    currentPageData: {
      type: Object,
    },
    backgroundColor: {
      type: String,
    },
    tarotBoxHistory: {
      type: Object,
    },
    currentChapterIndex: {
      type: Number,
    },
    isShare: {
      type: Boolean,
    },
  },
  emits: ['open-card-info'],
  data() {
    return {
    };
  },
  computed: {
    formattedContent() {
      let content = this.currentPageData.page_content.extra_answer;
      content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      content = content.replace(/\*(.*?)\*/g, '<em>$1</em>');
      content = marked(content);

      // 변환된 HTML 파싱
      const tempElement = document.createElement('div');
      tempElement.innerHTML = content;

      // 모든 <p> 요소에 gray4 클래스 추가
      const paragraphs = tempElement.getElementsByTagName('p');
      for (let i = 0; i < paragraphs.length; i++) {
        paragraphs[i].classList.add('gray4');
      }

      // '|END|' 텍스트 제거
      const endTags = tempElement.getElementsByTagName('p');
      for (let i = 0; i < endTags.length; i++) {
        if (endTags[i].textContent.includes('|END|')) {
          endTags[i].textContent = endTags[i].textContent.replace('|END|', '');
        }
      }

      // 수정된 HTML 반환
      return tempElement.innerHTML;
    },
    padding() {
      return this.isShare ? '48px 24px 200px 24px' : '100px 24px 200px 24px';
    }
  },
  async mounted() {
    this.$nextTick(() => {
      logEvent ('result_extra_answer_into', {});
    });
    const urlParams = new URLSearchParams(window.location.search);
    const historyId = urlParams.get('history_id');
    const isShare = urlParams.get('is_share');
    if(this.tarotBoxHistory.extra_question_status === 'new' && isShare !== 'true'){
     await markReadExtraAnswer(historyId);
    }
  },
  methods: {
  },
};
</script>

<style scoped>

p{
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  .gray4{
    font-size: 16px !important;
  }
}

.component-container {
  width: 100vw;
  position: relative;
  min-height: 100vh;
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
}

.extra-listings{
  width: 100%;
  margin-top: 38px;
}


.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
}

.heading-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 6px;
}

#symbol {
  height: 30px;
}

.content-texts {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  line-height: 26px;
  box-sizing: border-box;
}

.questions {
  width: 100%;
  padding: 32px 10px 32px 10px;
  box-sizing: border-box;
  border-top: 1px solid var(--bdGray9);
  position: relative;
}

.questions::before,
.questions::after,
.questions::before,
.questions::after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: var(--bdGray9);
  border: 1px solid var(--bdGray9);
  transform: rotate(45deg);
}

.questions::before {
  top: -3px;
  left: -3px;
}

.questions::after {
  top: -3px;
  right: -3px;
}

.questions::before {
  bottom: -3px;
  left: -3px;
}

.questions::after {
  bottom: -3px;
  right: -3px;
}

</style>