import store from "@/tarot_box/helper/store";

export function defaultSendDataToApp(dataToSend) {
    window.sendInfoToApp(JSON.stringify(dataToSend));
    console.log("sendInfoToApp", JSON.stringify(dataToSend));
}
export function sendActionToApp(actionString) {
    let dataToSend = {action: actionString};
    defaultSendDataToApp(dataToSend);
}

export async function loggingEvent(eventName, data = null) {
    let dataToSend;
    if(data) {
        dataToSend = { action: 'loggingEvent', name: eventName, param: data };
    } else {
        dataToSend = { action: 'loggingEvent', name: eventName };
    }
    defaultSendDataToApp(dataToSend);
}
export function goToMyTarotTab() {
    store.commit('initSafeAreaColorHistory');
    sendActionToApp('goToHistoryTab');
    sendActionToApp('showBottomTabBar');
    window.callNativeMethod(`{"action": "select_tab", "data": { "tab": 2}}`);
}