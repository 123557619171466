<script>
export default {
  name: "LoaderImage",
  props: {
    color: {
      type: String,
      default: '#514b82'
    },
    size: {
      type: Number,
      default: 50
    },
    speed: {
      type: Number,
      default: 1
    },
    borderWidth: {
      type: Number,
      default: 8
    }
  },
  computed: {
    borderSize() {
      return (this.borderWidth / 50) * this.size;
    },
    animationDuration() {
      return 0.8 / this.speed;
    }
  }
}
</script>

<template>
  <div class="loader" :style="{ width: size + 'px', borderWidth: borderSize + 'px', borderColor: color, '--animation-duration': animationDuration + 's' }"></div>
</template>

<style scoped>
.loader {
  aspect-ratio: 1;
  border-radius: 50%;
  border-style: solid;
  animation:
      l20-1 calc(var(--animation-duration) * 1) infinite linear alternate,
      l20-2 calc(var(--animation-duration) * 2) infinite linear;
}
@keyframes l20-1{
  0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
  12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
  25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
  50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
  62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
  75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
  100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}
</style>