<template>
  <div class="confirm-popup-back-page">
    <div class="confirm-popup">
      <div class="confirm-popup-contents">
        <div class="confirm-popup-contents-title">
          Are you sure you want to exit?
        </div>
        <div class="confirm-popup-contents-delete">
          Entered information will not be saved if you exit.<br>To continue writing, please click Continue.</div>
      </div>
      <div class="confirm-popup-button-area">
        <div class="confirm-popup-button-gray" v-wave @click="exit()">
          Exit
        </div>
        <div class="confirm-popup-button" v-wave @click="closePopup()">
          Continue
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  computed: {
  },
  emits: ['close-popup', 'exit'],
  props: {
  },
  data() {
    return {
    };
  },
  mounted() {
    logEvent('boxinput_exit_popup',{})
  },
  methods: {
    exit() {
      logEvent('boxinput_exit_popup_out',{})
      this.$emit('exit');
    },
    closePopup() {
      logEvent('boxinput_exit_popup_stay',{})
      this.$emit('close-popup');
    },
  },
}
</script>

<style scoped>
.confirm-popup-back-page{
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.80);
}

.confirm-popup{
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 40px;
  width: 90%;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  box-sizing: border-box;

  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
  z-index: 1001;
}

.confirm-popup-contents{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  gap: 16px;
}

.confirm-popup-contents-title{
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.confirm-popup-contents-delete {
  display: block;
  text-align: center;
  line-height: 140%;
}

.confirm-popup-button-area {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 9px;
}

.confirm-popup-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  background: var(--Grayscale-Gray3);
  color: #FFF;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}

.confirm-popup-button-gray {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  background: var(--Grayscale-Gray9);
  color: var(--Grayscale-Gray3);
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}
</style>