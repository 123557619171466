import axios from 'axios';
import * as Sentry from "@sentry/vue";
import md5 from 'crypto-js/md5';


export const HOST = "https://mytarot.kr";

export const api = axios.create({ baseURL: HOST });

function getCommonParams() {
    const newURL = new URLSearchParams(window.location.search);
    const isWeb = newURL.get('is_web') === 'true';
    const isShare = newURL.get('is_share') === 'true';

    if (isWeb || isShare) {
        return {}; // 빈 객체 반환
    }
    const rawOsType = newURL.get('os_type');
    let osType;
    if (rawOsType === 'aos') {
        osType = 'android';
    } else if (rawOsType === 'ios') {
        osType = 'ios';
    } else {
        osType = 'invalid_os_type'; // 기본값
    }
    const timestamp = Date.now();
    const sig = generateSig(timestamp, osType);

    return {
        target_os: 'web',
        app_ver: 84,
        language: 'ko',
        ts: timestamp,
        service: 'mytarot',
        sig: sig
    };
}

function generateSig(ts, targetOs) {
    const dataToHash = `${ts}${targetOs}playtown221218`;
    const md5Digest = md5(dataToHash);
    return md5Digest.toString();

}

export async function getTarotBoxInputDataByFetch(tarotBoxId) {
    let formTarotBoxData={"tarot_box_id": tarotBoxId}
    const response = await fetch(HOST+'/my_tarot/input_meta_data', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formTarotBoxData)
    })
    if (!response.ok) {
        console.error('API 호출에 실패했습니다.');
        return null;
    }
    const jsonData = await response.json();
    if(jsonData.result !== "success"){
        console.log(jsonData.message)
    }
    return JSON.parse(jsonData.data);
}
export async function getFaceDataByFetch(imageUrl) {
    const formData = {img_url: imageUrl};
    const response = await fetch(HOST+'/my_tarot/get_face_data', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
    if (!response.ok) {
        Sentry.captureMessage(`Old! Get Face Data API Failed: ${window.location.href}`);
    }
    return await response.json();
}

export async function uploadImageFile(imageFile) {
    const formData = {img_file: imageFile};
    let res = await axios.post(HOST+'/my_tarot/boxes/upload_image', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    return res.data;
}

export async function getMyTarotResultByFetch(hashId) {
    let formData={"hash_id": hashId}
    const response = await fetch(HOST+'/my_tarot/result_hash', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
    if (!response.ok) {
        Sentry.captureMessage(`Result Hash API Failed: ${window.location.href}`);
    }
    const jsonData = await response.json();

    if(jsonData.message === "타로박스 결과를 찾을 수 없습니다."){
        Sentry.captureMessage(`Result Hash API Failed: ${window.location.href}`);
        return;
    }

    if(jsonData.result !== "success"){
        console.log(jsonData.message)
        console.log(jsonData.data)
    }
    return jsonData.data;
}

export async function getMySajuByFetch(userId) {
    let formData={"tarot_user_id": userId}
    const response = await fetch(HOST+'/my_tarot/get_my_saju_date', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
    if (!response.ok) {
        Sentry.captureMessage(`Old! Get Saju Date API Failed: ${window.location.href}`);
    }
    const jsonData = await response.json();
    if(jsonData.result !== "success"){
        console.log(jsonData.message)
    }
    return jsonData.data;
}

export async function getMyManseDataByFetch(hashId) {
    let formData = {"hash_id": hashId}
    const response = await fetch(HOST + '/my_tarot/get_manse_data_by_hash_id', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
    if (!response.ok) {
        Sentry.captureMessage(`Old! Get Manse Data API Failed: ${window.location.href}`);
    }
    const jsonData = await response.json();
    if (jsonData.result !== "success") {
        console.log(jsonData.message)
    }
    return jsonData.data;
}

export async function makeBoxResult(userId, phoneNumber, userInputData, pickedCardIds, tarotBoxId) {
    const response = await defaultApiCall('/my_tarot/make_box_result', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        user_input_params: userInputData,
        picked_card_ids: pickedCardIds,
        tarot_box_id: tarotBoxId
    });
    return response;
}

export async function purchaseBox(userId, phoneNumber, tarotBoxHistoryId) {
    const response = await defaultApiCall('/my_tarot/purchase_box', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        tarot_box_history_id: tarotBoxHistoryId,
    });
    return response;
}

export async function getTarotUserInfo(userId, phoneNumber) {
    const response = await defaultApiCall('/my_tarot/tarot_user_info', {
        phone_number: phoneNumber,
        tarot_user_id: userId
    });
    return response;
}

export async function getUserCloverHistory(userId, phoneNumber) {
    const response = await defaultApiCall('/my_tarot/user_history', {
        phone_number: phoneNumber,
        tarot_user_id: userId
    });
    return response;
}

//2024.02.19 업데이트 by rob
//실제 유저 정보 없이도 타로박스 리스트를 불러오는 함수
export async function getTarotBoxItemsByFetch(phoneNumber, userId, isWeb) {
    const response = await defaultApiCall('/my_tarot/home/box_data_v2', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        is_web: isWeb
    });
    console.log()
    return response;
}

export async function getFullTarotBoxItems(phoneNumber, userId, isWeb) {
    const response = await defaultApiCall('/my_tarot/home/box_data', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        is_web: isWeb
    });
    return response;
}

export async function getRecommendBoxIds(phoneNumber, userId) {
    const response = await defaultApiCall('/my_tarot/home/recommend_box_ids', {
        phone_number: phoneNumber,
        tarot_user_id: userId
    });
    return response;
}

export async function recordTarotBoxClick(phoneNumber, userId, tarotBoxId) {
    const response = await defaultApiCall('/my_tarot/home/record_box_click', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        tarot_box_id: tarotBoxId
    });
    return response;
}

export async function askExtraQuestion(tarotBoxHistoryId, extraQuestion) {
    const response = await defaultApiCall('/my_tarot/ask_extra_question', {
        tarot_box_history_id: tarotBoxHistoryId,
        extra_question: extraQuestion
    });
    return response;
}

export async function markReadExtraAnswer(tarotBoxHistoryId) {
    const response = await defaultApiCall('/my_tarot/read_extra_question', {
        tarot_box_history_id: tarotBoxHistoryId
    });
    return response;
}

//입력페이지v2에서 사주명식을 내리는 함수

export async function getSajuCharacters(date, sajutime){
    const response = await defaultApiCall('/my_tarot/saju_characters', {
        date: date,
        sajutime: sajutime
    });
    return response;
}


//타로박스 리스트 불러오는 함수
export async function getTarotBoxItems(phoneNumber, userId) {
    const response = await defaultApiCall('/my_tarot/available_boxes', {
        phone_number: phoneNumber,
        tarot_user_id: userId
    });
    return response;
}

//2024.01.11 업데이트 (나의 타로박스 히스토리, 타로박스 삭제, 리뷰 리스트, 리뷰 전송)
export async function getUserBoxHistory(phoneNumber, userId) {
    const response = await defaultApiCall('/my_tarot/user_box_history', {
        phone_number: phoneNumber,
        tarot_user_id: userId
    });
    return response;
}

export async function deleteBoxHistory(phoneNumber, userId, tarotBoxHistoryId) {
    const response = await defaultApiCall('/my_tarot/remove_box', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        tarot_box_history_id: tarotBoxHistoryId
    });
    return response;
}

export async function getBoxReviewList(tarotBoxId){
    const response = await defaultApiCall('/my_tarot/review_list', {
        tarot_box_id: tarotBoxId
    });
    return response;
}

export async function submitBoxReview(phoneNumber, userId, tarotBoxId, tarotBoxHistoryId, userReaction, userReview){
    const response = await defaultApiCall('/my_tarot/write_feedback', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        tarot_box_id: tarotBoxId,
        tarot_box_history_id: tarotBoxHistoryId,
        user_reaction: userReaction,
        user_review: userReview
    });
    return response;
}

export async function startSMSAuth(phoneNumber) {
    const response = await defaultApiCall('/my_tarot/start_sms_auth', {
        phone_number: phoneNumber
    });
    return response;
}

export async function finishSMSAuth(phoneNumber, certNumber, displayName, installReferrer) {
    const response = await defaultApiCall('/my_tarot/web/finish_sms_auth', {
        phone_number: phoneNumber,
        cert_number: certNumber,
        display_name: displayName,
        install_referrer: installReferrer
    });
    return response;
}

export async function finishAppSMSAuth(phoneNumber, certNumber, installReferrer){
    const response = await defaultApiCall('/my_tarot/finish_sms_auth', {
        phone_number: phoneNumber,
        cert_number: certNumber,
        install_referrer: installReferrer,
    });
    return response;
}

export async function getPayplePartnerAuth() {
    const response = await defaultApiCall('/my_tarot/web/payple_partner_auth', {});
    return response;
}

export async function changeNickname (phoneNumber, userId, nickname) {
    const response = await defaultApiCall('/my_tarot/update_display_name', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        display_name: nickname
    });
    return response;
}

export async function removeUser(phoneNumber, userId) {
    const response = await defaultApiCall('/my_tarot/remove_user', {
        phone_number: phoneNumber,
        tarot_user_id: userId
    });
    return response;
}

export async function updateReminderTime(phoneNumber, userId, reminderTime) {
    const response = await defaultApiCall('/my_tarot/update_reminder_time', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        reminder_time: reminderTime
    });
    return response;
}

export async function getInviteHistory(phoneNumber, userId) {
    const response = await defaultApiCall('/my_tarot/referral_history', {
        phone_number: phoneNumber,
        tarot_user_id: userId
    });
    return response;
}

export async function makeReferral(phoneNumber, userId, referralCode) {
    const response = await defaultApiCall('/my_tarot/make_referral', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        referral_code: referralCode
    });
    return response;
}

export async function getMyDailyFortune (phoneNumber, userId, buildNumber) {
    const response = await defaultApiCall('/my_tarot/my_daily_fortune_info', {
        phone_number: phoneNumber,
        tarot_user_id: userId,
        build_number: buildNumber
    });
    return response;
}

export async function getPlanetaryPosition (birthDate, birthTime, BirthPlace, name){
    const response = await defaultApiCall('/my_tarot/planetary_position', {
        birth_date: birthDate,
        birth_time: birthTime,
        birth_place: BirthPlace,
        gender: "female",
        name: name
    });
    return response;
}


async function defaultApiCall(url, formData) {
    const MAX_RETRY = 2;
    let attempts = 0;

    while (attempts <= MAX_RETRY) {
        try {
            const commonParams = getCommonParams();
            const combinedData = { ...commonParams, ...formData };

            const response = await fetch(`${HOST}${url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(combinedData)
            });

            if (!response.ok) {
                console.log("API 호출에 실패했습니다.");
                Sentry.captureMessage(`${url} API Failed: ${window.location.href}, ${JSON.stringify(combinedData)}`);
            }

            const jsonData = await response.json();
            if (jsonData.result != "success") {
                console.log(jsonData.message);
            }
            console.log(jsonData);
            return jsonData;
        }
        catch (error) {
            attempts++;
            await sleep(500);
            console.error(`API 통신 중 오류 발생 (시도 ${attempts}):`, error);

            if (attempts > MAX_RETRY) {
                console.error("API 호출에 실패했습니다.");
                return { result: 'failed' };
            }
        }
    }
}


function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
