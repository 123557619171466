<template>
  <div v-if="tarotCardMeaning && tarotCardInfo" class="sheet-container">
    <div class="sheet-title">
      <strong>{{tarotCardMeaning.card_name}}</strong>
      <img :src="require('@/tarot_box/pages/box_result_v2/assets/xbutton.svg')" alt="close" @click="$emit('close')"/>
    </div>
    <div class="sheet-content">
      <div class="info-content">
        <div class="info-title">질문</div>
        <div class="info-texts">{{tarotCardInfo.short_desc}}</div>
      </div>
      <div class="info-content">
        <div class="info-title">의미</div>
        <div class="info-texts">{{tarotCardMeaning.meaning}}</div>
      </div>
      <div class="info-content">
        <div class="info-title">주요 상징</div>
        <div class="info-texts">{{tarotCardMeaning.sign}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TarotInfoComponent',
  props: {
    payload: {
      type: Array,
    }
  },
  computed: {
    tarotCardMeaning() {
      return this.payload[0];
    },
    tarotCardInfo() {
      return this.payload[1];
    }
  }
}
</script>

<style scoped>
.sheet-container{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  padding: 12px 24px 76px 24px;
  box-sizing: border-box;
  position: relative;
}

.sheet-title{
  font-family: "SUIT Variable", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 30px 20px 10px 20px;
  box-sizing: border-box;
  background: white;
  z-index: 9999;
}

.sheet-content{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;

  margin-top: 60px;
}

.info-content{
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 16px;
  gap: 12px;
}

.info-content:first-child{
  margin-top: 8px;
  border-top: 1px solid #EEE;
  padding-top: 20px;
}

.info-title{
  display: flex;
  width: 10%;
  color: var(--Grayscale-Gray2, #242424);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.info-texts{
  width: 90%;
  display: flex;
  color: var(--Grayscale-Gray4, #555);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}</style>