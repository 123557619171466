<template>
  <div class="check-wrapper">
    <div class="proceed-button-wrapper" v-safe-area-bottom>
      <div v-wave class="proceed-button-white" @click="$emit('close-later')">BACK</div>
      <div v-wave v-if="this.currentIndex + 1 === this.totalIndex" class="proceed-button" @click="goNext">DONE</div>
      <div v-wave v-else class="proceed-button" @click="goNext">NEXT</div>
    </div>
    <div class="check-container">
      <div class="sections" v-safe-area-top>
        <InputHeader
            :user-name="userName"
            :is-checker="true"
            :current-index="currentIndex"
            :input-sections="inputSections"/>
        <div class="content-wrapper">
          <div class="answers">
            <div v-for="(answer, index) in formattedUserAnswers[currentIndex - 1]" :key="index" class="answer">
              <div class="answer-title">{{ answer.value }}</div>
            </div>
          </div>
          <div class="planetary-table" v-if="shouldShowPlanetaryTable">
            <div class="title3 gray3" style="margin-bottom: 16px;">{{ defineYours(currentIndex) }} Chart</div>
            <div v-if="isPlanetaryDataLoading" class="table-container">
              <div class="table-row header">
                <div class="sign-column">
                  <div class="text3 gray3">SIGNS</div>
                </div>
                <div class="name-column">
                  <div class="text3 gray3">PLANETS</div>
                </div>
                <div class="house-column">
                  <div class="text3 gray3">HOUSES</div>
                </div>
              </div>
              <div v-for="(row, index) in skeletonChartData" :key="index" class="table-row">
                <div class="sign-column" :class="{ 'hide-content': !row.showSignContent }">
                  <div class="skeleton-box sign-skeleton"></div>
                </div>
                <div class="name-column">
                  <div class="name-item">
                    <div class="skeleton-box symbol-skeleton"></div>
                    <div class="skeleton-box name-skeleton"></div>
                  </div>
                </div>
                <div class="house-column" :class="{ 'hide-content': !row.showHouseContent }">
                  <div class="skeleton-box house-skeleton"></div>
                </div>
              </div>
            </div>
            <div v-else-if="isPlanetaryDataLoaded" class="table-container">
              <div class="table-row header">
                <div class="sign-column">
                  <div class="text3 gray3">SIGNS</div>
                </div>
                <div class="name-column">
                  <div class="text3 gray3">PLANETS</div>
                </div>
                <div class="house-column">
                  <div class="text3 gray3">HOUSES</div>
                </div>
              </div>
              <div v-for="(row, index) in processedChartData" :key="index" class="table-row">
                <div class="sign-column" :class="{ 'hide-content': !row.showSignContent }">
                  <div class="text1 gray3">{{ row.sign }}</div>
                </div>
                <div class="name-column">
                  <div class="name-item">
                    <NatalChartSymbols :sign="row.name"/>
                    <div class="text1">{{ row.name.toUpperCase() }}</div>
                  </div>
                </div>
                <div class="house-column" :class="{ 'hide-content': !row.showHouseContent }">
                  <div class="title4">{{ row.house }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputHeader from "@/tarot_box/pages/box_input_v2/components/InputHeader.vue";
import {logEvent} from "@/tarot_box/helper/native_api";
import NatalChartSymbols from "@/tarot_box/pages/box_input_v2/assets/NatalChartSymbols.vue";

export default {
  name: "InputCheckComponent",
  components: {
    InputHeader,
    NatalChartSymbols,
  },
  props: {
    userName: {
      type: String,
    },
    userAnswers: {
      type: Array,
    },
    inputSections: {
      type: Array,
      required: true,
    },
    sajuData: {
      type: Array,
      default: () => [],
    },
    partnerSajuData: {
      type: Array,
      default: () => [],
    },
    currentIndex: {
      type: Number,
    },
    totalIndex: {
      type: Number,
    },
    tarotBoxData: {
      type: Object,
    },
    planetaryPositionData: {
      type: Object,
    }
  },
  data() {
    return {
      openSajuInfoPopup: false,
    };
  },
  mounted() {
    this.initPage();
  },
  computed: {
    formattedUserAnswers() {
      const result = [];
      for (const section of this.inputSections) {
        const sectionAnswers = [];
        for (const input of section.inputs) {
          const answers = this.userAnswers.filter(answer =>
              answer.key === input.key && answer.inputFormType === input.inputFormType
          );
          sectionAnswers.push(...answers);
        }
        result.push(sectionAnswers);
      }
      return result;
    },
    shouldShowPlanetaryTable() {
      if (!this.inputSections || this.currentIndex <= 0) return false;
      const currentSection = this.inputSections[this.currentIndex - 1];
      return currentSection && (currentSection.sectionType === 'personalInfo' || currentSection.sectionType === 'partnerInfo');
    },
    isPlanetaryDataLoading() {
      return this.shouldShowPlanetaryTable && (!this.planetaryPositionData || Object.keys(this.planetaryPositionData).length === 0);
    },
    isPlanetaryDataLoaded() {
      return this.shouldShowPlanetaryTable && this.planetaryPositionData && Object.keys(this.planetaryPositionData).length > 0;
    },
    processedChartData() {
      if (!this.planetaryPositionData || !this.planetaryPositionData.charts) {
        return [];
      }

      const sortedData = [...this.planetaryPositionData.charts].sort((a, b) => {
        if (a.house !== b.house) return a.house - b.house;
        if (a.sign !== b.sign) return a.sign.localeCompare(b.sign);
        return a.name.localeCompare(b.name);
      });

      let result = [];
      let lastSign = null;

      sortedData.forEach((item, index, array) => {
        const showSignContent = item.sign !== lastSign;
        const showHouseContent = index === array.length - 1 || item.house !== array[index + 1].house;

        result.push({
          sign: item.sign,
          name: item.name,
          house: item.house,
          showSignContent,
          showHouseContent
        });

        lastSign = item.sign;
      });

      return result;
    },
    skeletonChartData() {
      const skeletonData = [];
      const signs = ['Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo', 'Libra', 'Scorpio', 'Sagittarius', 'Capricorn', 'Aquarius', 'Pisces'];
      const houses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

      for (let i = 0; i < 10; i++) {
        const sign = signs[Math.floor(Math.random() * signs.length)];
        const house = houses[Math.floor(Math.random() * houses.length)];
        skeletonData.push({ sign, house });
      }

      skeletonData.sort((a, b) => {
        if (a.house !== b.house) return a.house - b.house;
        return a.sign.localeCompare(b.sign);
      });


      // eslint-disable-next-line
      let lastHouse = null;
      let lastSign = null;

      return skeletonData.map((item, index, array) => ({
        sign: item.sign,
        house: item.house,
        showSignContent: item.sign !== lastSign,
        showHouseContent: index === array.length - 1 || item.house !== array[index + 1].house,
        lastSign: (lastSign = item.sign),
        lastHouse: (lastHouse = item.house)
      }));
    },
  },
  methods: {
    goNext() {
      let sectionType = this.inputSections[this.currentIndex -1].sectionType
      let convertedSectionType;
      if (sectionType === 'personalInfo'){
        convertedSectionType = 'myinfo';
      } else if (sectionType === 'partnerInfo'){
        convertedSectionType = 'otherinfo';
      } else if (sectionType === 'survey'){
        convertedSectionType = 'survey';
      } else {
        convertedSectionType = 'additional';
      }
      logEvent(`boxinput_${convertedSectionType}_go_click`, {
        'tarot_box_id': this.tarotBoxData.id,
        'tarot_box_name': this.tarotBoxData.title,
      })
      this.$emit('close')
      setTimeout(() => {
        this.$emit('proceed');
      }, 500)
    },
    initPage(){
      this.$nextTick(() => {
        if (!this.inputSections || this.inputSections.length === 0) {
          setTimeout(() => {
            this.initPage();
          }, 500);
        } else {
          let sectionType = this.inputSections[this.currentIndex -1].sectionType
          let convertedSectionType;
          if (sectionType === 'personalInfo'){
            convertedSectionType = 'myinfo';
          } else if (sectionType === 'partnerInfo'){
            convertedSectionType = 'otherinfo';
          } else if (sectionType === 'survey'){
            convertedSectionType = 'survey';
          } else {
            convertedSectionType = 'additional';
          }
          logEvent(`boxinput_${convertedSectionType}_pre_into`, {
            'tarot_box_id': this.tarotBoxData.id,
            'tarot_box_name': this.tarotBoxData.title,
          })
        }
      });
    },
    getRandomItemCount() {
      return Math.floor(Math.random() * 3) + 1;
    },
    defineYours(currentIndex) {
      return currentIndex === 1 ? 'Your' : `Partner's`;
    }
  }
}
</script>

<style scoped>
.check-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--Grayscale-Gray12);
  box-sizing: border-box;
  overflow-y: scroll;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}

.proceed-button-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  box-sizing: border-box;
  background-color: white;
  gap: 8px;
  z-index: 500;
}

.proceed-button {
  display: flex;
  flex: 5;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 13px;
  background: var(--Grayscale-Gray3);
  color: white;
  font-size: 16px;
  font-weight: 700;
}

.proceed-button-white {
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  padding: 13px;
  background: var(--Grayscale-Gray12);
  color: var(--Grayscale-Gray3);
  outline: 2px solid var(--Grayscale-Gray3);
  font-size: 16px;
  font-weight: 700;
}

.check-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color: white;
  padding: 54px 24px 70px 24px;
  box-sizing: border-box;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}

.content-wrapper {
  animation: fadeInDown 0.5s ease-out 0.5s both;
  width: 100%;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.sections {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}

.answers {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 12px;
  gap: 8px;
}

.answer {
  flex: 0 0 auto;
  max-width: 100%;
}

.answer-title {
  display: inline-block;
  padding: 8px 16px;
  background: var(--Grayscale-Gray11, #F5F5F5);
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  color: var(--Grayscale-Gray3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  box-sizing: border-box;
}

.planetary-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0 20px 0;
  box-sizing: border-box;
  min-height: 300px;
}

.table-container {
  overflow: hidden;
}

.table-row {
  display: flex;
}

.table-row:last-child {
  border-bottom: none;
}

.sign-column, .name-column, .house-column {
  padding: 12px;
  display: flex;
  align-items: center;
}

.sign-column {
  flex: 1;
  justify-content: flex-start;
  border-right: 1px solid var(--Grayscale-Gray3);
  border-top: 1px solid var(--Grayscale-Gray3);
}

.name-column {
  flex: 1;
  border-right: 1px solid var(--Grayscale-Gray3);
}

.house-column {
  flex: 1;
  justify-content: center;
  border-bottom: 1px solid var(--Grayscale-Gray3);
  overflow: hidden;
}

.table-row:first-child .sign-column,
.table-row:first-child .name-column,
.table-row:first-child .house-column {
  padding-top: 6px;
  padding-bottom: 6px;
}

.table-row:last-child .house-column {
  border-bottom: none;
}

.table-row:nth-child(2) .sign-column {
  border-top: none;
}

.hide-content .text1, .hide-content .title4{
  visibility: hidden;
}

.hide-content{
  border-top: none;
  border-bottom: none;
}

.table-row:not(:first-child) .sign-column.hide-content {
  border-top: none;
}

.name-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  color: var(--Grayscale-Gray5);
}

.table-row.skeleton {
  animation: skeleton-loading 1.5s infinite;
}

.skeleton-box {
  background: #e0e0e0;
  border-radius: 4px;
}

.sign-skeleton {
  width: 60%;
  height: 20px;
}

.symbol-skeleton {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.name-skeleton {
  width: 60%;
  height: 20px;
}

.house-skeleton {
  width: 30px;
  height: 20px;
}

@keyframes skeleton-loading {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.text1, .title4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text1 {
  font-size: 14px;
  font-weight: 500;
}

.title4 {
  font-size: 16px;
  font-weight: 600;
}

.name-item :deep(svg) {
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .sign-column {
    flex: 3;
  }

  .name-column {
    flex: 5;
  }

  .house-column {
    flex: 1;
  }

  .proceed-button-wrapper {
    padding: 10px 24px;
  }

  .answer-title {
    font-size: 16px;
    padding: 6px 12px;
  }
}

.table-row.header {
  font-weight: 700;
}

.table-row.header .sign-column,
.table-row.header .name-column,
.table-row.header .house-column {
  border-bottom: 1px solid var(--Grayscale-Gray3);
  border-top: 1px solid var(--Grayscale-Gray3);
}

.table-row:not(.header):first-of-type .sign-column,
.table-row:not(.header):first-of-type .name-column,
.table-row:not(.header):first-of-type .house-column {
  border-top: none;
}

/* 새로 추가된 스타일 */
.table-row .sign-column,
.table-row .name-column,
.table-row .house-column {
  display: flex;
  align-items: center;
  padding: 12px;
}

.table-row .sign-column {
  justify-content: flex-start;
}

.table-row .name-column {
  justify-content: flex-start;
}

.table-row .house-column {
  justify-content: center;
}
</style>