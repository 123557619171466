<template>
  <transition name="fade">
    <div class="toast" v-show="showToast" :class="{ 'show': showToast }">
      {{ toastMessage }}
    </div>
  </transition>
  <div class="screen">
    <Loading v-model:active="isMakeBoxProcessing"
             loader="spinner"
             color="#1E2352"
             :opacity="1"
             :z-index="99">
    </Loading>
    <CheckoutPageHeaderBar title="ORDER" @open-exit-popup="openExitPopup"/>
    <CheckoutVerifyPopup
        :isActive="popupActive"
        :phone-number="userPhoneNumber"
        :display-name="userName"
        :install-referrer="installReferrer"
        @showToastMessage="showToastMessage"
        @verificationSuccess="handleVerificationSuccess"
        @closePopup="closePopup"/>
    <CheckoutExitPopup
        :isActive="isExitPopupActive"
        @closeExitPopup="closeExitPopup"/>
    <div class="wrapper">
<!--      <CheckoutItemInfo :boxData="boxData"/>-->

      <div class="order-info">
        <div class="product-info">
          <div class="info-title title3">
            Order Info
          </div>
          <div class="info-details">
            <div class="detail-thumbnail">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBJQeF5WofXECCFt4M-0ZAFWya_vNt1jz11Q&s" alt="tarot-box-thumbnail" id="product-thumbnail">
            </div>
            <div class="detail-title title4">
              Are We Soulmates
            </div>
          </div>
        </div>
        <div class="price-info title4">
          Price
          <div class="prices">
            <div class="original-price">
              $28.00
            </div>
            <div class="new-price title2">
              $22.00
            </div>
          </div>
        </div>
      </div>
      <hr style="height: 8px; width: 100%; background: #F5F5F5; border: none; margin: 0">
      <div class="user-info">
        <div class="user-info-header title3">
          Enter Email to Get Your Reading
        </div>
        <div class="user-nickname">
          <div class="input-label">
            Name/Nickname
          </div>
          <input class="text-input title3" placeholder="Enter your name/nickname"/>
        </div>
        <div class="user-email">
          <div class="input-label">
            E-mail
          </div>
          <input class="text-input title3" placeholder="Enter your email address"/>
        </div>
        <div class="disclaimer-section">
          <div class="disclaimer">
            Refunds are <span class="highlight">not possible</span> due to incorrect email entry.<br/>
            <span class="highlight">Please fill it out carefully</span>
          </div>
          <div class="disclaimer-contact">
            Customer Service: dev@1zlabs.com
          </div>
        </div>
      </div>
      <CheckoutConsentForm
          @update:agreements="updateAgreements"
          @update:isAllAgree="updateIsAllAgree"
      />
    </div>
    <div style="height: 28px"></div>
    <button class="button buttonL" :disabled="isPaypleProcessing" :class="{ 'enabled': isVerified }" @click="handlePayplePayment">
      BUY NOW
    </button>
  </div>
</template>

<script>
import CheckoutConsentForm from "@/tarot_box/pages/web_checkout/components/CheckoutConsentForm.vue";
import CheckoutVerifyPopup from "@/tarot_box/pages/web_checkout/components/CheckoutVerifyPopup.vue";
import CheckoutPageHeaderBar from "@/tarot_box/pages/web_checkout/components/CheckoutPageHeaderBar.vue";
import CheckoutExitPopup from "@/tarot_box/pages/web_checkout/components/CheckoutExitPopup.vue";

import {mapActions, mapGetters} from "vuex";
import {getPayplePartnerAuth, makeBoxResult, startSMSAuth} from "@/tarot_box/helper/mytarot_api";
import {logEvent} from "@/tarot_box/helper/native_api";
import * as Sentry from "@sentry/vue";
import Loading from "vue-loading-overlay";

export default {
  name: "WebCheckoutPage",
  components: {
    Loading,
    CheckoutPageHeaderBar, CheckoutConsentForm, CheckoutVerifyPopup, CheckoutExitPopup},
  data() {
    return {
      boxId: null,
      boxData: {},
      userPhoneNumber: null,
      userName: null,
      userId: null,
      userInputData: null,
      userPickedCards: '',
      userBoxHistoryId: null,
      userBoxCreatedAt: null,

      installReferrer: '',
      agreements: {
        service: false,
        privacy: false,
      },
      isAllAgree: false,
      isVerified: false,
      isMakeBoxProcessing: false,
      isPaypleProcessing: false,
      popupActive: false,
      isExitPopupActive: false,
      verificationCode: null,
      authKey: null,
      returnUrl: null,
      showToast: false,
      toastMessage: '',

      isRetry: false,
    }
  },
  mounted() {
    window.onBackButtonClick = this.handleBackButtonClick;
    this.adjustViewportHeight();
    window.addEventListener('resize', this.adjustViewportHeight);
    this.boxId = parseInt(this.$route.params.boxId);
    this.boxData = this.tarotBoxes.find(box => box.id === this.boxId);
    if (this.user?.webReferrer) {
      this.installReferrer = this.user.webReferrer;
    }
    if(this.boxData){
      logEvent('purchase_into', {
        "tarot_box_name": this.boxData.title,
        "tarot_box_id": this.boxData.id,
        "price": this.boxData.salePriceWon,
      });
    }
    const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
    const status = urlParams.get('status');
    const userIdFromUrl = urlParams.get('tuno')
    if(status && status === 'fail'){
      this.isRetry = true;
      this.userBoxHistoryId = parseInt(urlParams.get('history_id'));
      this.userPhoneNumber = this.user.phoneNumber;
      this.userName = this.user.userNickname;
      this.userId = userIdFromUrl;
      console.log(this.userPhoneNumber, this.userName);

      this.showToastMessage('결제취소 - 다시 시도해주세요')
      this.reAuthPayple();
      this.isVerified = true;
      this.agreements.service = true;
      this.agreements.privacy = true;
      this.isAllAgree = true;
      return;
    }
    const queryParams = new URLSearchParams(decodeURIComponent(window.location.search));

    // picked_cards 검증
    const pickedCardsParam = queryParams.get('picked_cards');
    if (pickedCardsParam) {
      if (/^\d+(,\d+)*$/.test(pickedCardsParam)) {
        this.userPickedCards = pickedCardsParam;
      } else {
        console.error('Invalid picked_cards format');
        Sentry.captureMessage(`Invalid picked_cards format: ${pickedCardsParam}`);
      }
    }

    // user input data 수집 및 검증
    const userInputData = [];
    queryParams.forEach((value, key) => {
      if (key !== 'picked_cards' && key !== 'is_web') {
        userInputData.push({ key, value: decodeURIComponent(value) });
      }
    });

    if(this.isRetry === false && this.userBoxHistoryId === null){
      console.log('no input data')
    }

    //mastro 테스트 모드
    this.showToastMessage('Test Mode Activated.\nRedirecting to Mytarot Page');
    setTimeout(() => {
      this.$router.push("/tarot_box/mytarot?tarot_user_id=121904&phone_number=01032382218&build_number=85&os_type=ios&inset_top=10&inset_bottom=0")
    }, 3000);

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.adjustViewportHeight);
  },
  computed: {
    ...mapGetters(['user','tarotBoxes','tempCardData','tempInputData','inputData']),
    formattedPhoneNumber: {
      get() {
        if (this.userPhoneNumber === null || this.userPhoneNumber === undefined) {
          return '';
        }
        return this.userPhoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3');
      },
      set(newValue) {
        this.userPhoneNumber = newValue.replace(/\D/g, '');
      }
    },
    isButtonEnabled() {
      return this.isAllAgree && this.userName !== null && this.userName !=="" && this.userPhoneNumber?.length === 11;
    },
  },
  methods: {
    ...mapActions(['setUserData']),
    adjustViewportHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    showToastMessage(message){
      this.toastMessage = message;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 4000);
    },
    handleBackButtonClick() {
      console.log(this.$route.meta.fromPath);
      if (!this.$route.meta.fromPath.includes('result')) {
        this.$router.go(-2);
        return 'back';
      } else {
        this.$router.go(-2);
        return 'back';
      }
    },
    updateAgreements(value) {
      this.agreements = value;
    },
    updateIsAllAgree(value) {
      this.isAllAgree = value;
    },
    async startSMSAuth(){
      this.isLoading = true;
      if(this.isAllAgree === false){
        logEvent("purchase_agree_popup", {});
        this.showToastMessage('필수약관에 동의해주세요');
        this.isLoading = false;
        return;
      } else if (this.isButtonEnabled === false ){
        this.showToastMessage('모든 정보를 입력해주세요');
        this.isLoading = false;
        return;
      } else{
        logEvent('purchase_req_num_click', {});
        let res = await startSMSAuth(this.userPhoneNumber);
        if(res.result === 'success'){
          this.isLoading = false;
          this.popupActive = true;
          logEvent('purchase_authnumb_popup',{});
        } else {
          this.showToastMessage('올바른 전화번호가 아닙니다. 다시 시도해주세요');
          this.isLoading = false;
        }
      }
    },
    async handleVerificationSuccess(payload) {

      if(payload.userId === '' || payload.userId === null || payload.userId === undefined){
        Sentry.captureMessage(`FinishSMSAuth data is not updated: ${payload.userId},  ${payload.authKey}, ${payload.returnUrl}, ${payload.userPhoneNumber}, ${payload.userPickedCards}, ${payload.boxId}, ${window.location.href}`);
      }

      this.authKey = payload.authKey;
      this.userId = payload.userId;
      this.returnUrl = payload.returnUrl;
      await this.makeNewBoxHistory();
      if(this.authKey && this.userId && this.returnUrl){
       this.showToastMessage('인증이 완료되었습니다.');
        this.isVerified = true;
      }else {
        this.showToastMessage('인증을 실패했습니다. 다시 시도해주세요.');
      }
    },
    async makeNewBoxHistory(){
      //TODO: InputData없을 경우
      if(this.userInputData === '[]' || this.userInputData === null || this.userInputData === undefined || this.userInputData === '') {
        console.log("no input data");
        Sentry.captureMessage("No Input Data while processing Web Checkout: " + this.userInputData + ", " + this.userId + ", " + this.userPhoneNumber + ", " + this.userPickedCards + ", " + this.boxId + `, ${window.location.href}`);
      }

      if(this.userId === '' || this.userId === null || this.userId === undefined){
        Sentry.captureMessage("No userId while processing Web Checkout: " + this.userInputData + ", " + this.userId + ", " + this.userPhoneNumber + ", " + this.userPickedCards + ", " + this.boxId `, ${window.location.href}`);

        if(this.user === null || this.user === undefined) {
          console.log("no user data");
          Sentry.captureMessage("No User Data while processing Web Checkout: " + this.userInputData + ", " + this.userId + ", " + this.userPhoneNumber + ", " + this.userPickedCards + ", " + this.boxId `, ${window.location.href}`);
        }

        if(this.user.userId === null || this.user.userId === undefined || this.user.userId === '') {
          console.log("no user id");
          Sentry.captureMessage("No user.user.id while processing Web Checkout: " + this.userInputData + ", " + this.userId + ", " + this.userPhoneNumber + ", " + this.userPickedCards + ", " + this.boxId `, ${window.location.href}`);
        }
      }



      let boxRes = await makeBoxResult(this.userId, this.userPhoneNumber, this.userInputData, this.userPickedCards, this.boxId);
      this.isMakeBoxProcessing = true;
      if(boxRes.result === 'success'){
        this.userBoxHistoryId = boxRes.data.id;
        this.isMakeBoxProcessing = false;
        logEvent('make_box_history', {
          "tarot_box_id": this.boxData.id,
          "user_id": boxRes.data.tarot_user_id,
          "user_input_data": this.userInputData,
          "picked_cards": this.userPickedCards,
          "history_id": this.userBoxHistoryId
        });

        this.$store.dispatch('setUserData', {
          user_id: boxRes.data.tarot_user_id,
          phone_number: this.userPhoneNumber,
          user_nickname: this.userName
        });
      }
    },
    handleDataError(){
      this.showToastMessage('이용에 불편을 드려 죄송합니다.\n' + '휴대폰 인증을 다시 부탁 드리겠습니다.');
      Sentry.captureMessage("No UserID while proceeding to payple Web Checkout: " + this.userBoxHistoryId + ", " + this.userInputData + ", " + this.userId + ". " + this.userPhoneNumber + ", " + this.userPickedCards + ", " + this.boxId + `, ${window.location.href}`);
      logEvent('checkout_fail_toast_show', {
        "history_id" : this.userBoxHistoryId,
      });
      if(this.userInputData === '[]' || this.userInputData === null || this.userInputData === undefined || this.userInputData === '') {
        setTimeout(() => {
          window.location.href = "https://mytarot.co.kr"
        }, 4500);
      }
      setTimeout(() => {
        location.reload(true);
      }, 4500);
    },

    handlePayplePayment(){
      if(this.userId === '' || this.userId === null || this.userId === undefined){
        this.handleDataError();
        return;
      }

      logEvent('purchase_gogogo_click', {
        "tarot_box_id": this.boxData?.id,
        "tarot_box_name": this.boxData?.title,
        "price": this.boxData?.salePriceWon
      });
      this.isPaypleProcessing = true;

      logEvent('purchase_pg', {
        "tarot_box_id": this.boxData?.id,
        "tarot_box_name": this.boxData?.title,
        "price": this.boxData?.salePriceWon,
      })
      //delete all params from url
      window.history.pushState({}, document.title, window.location.pathname);
      window.fbq('track', 'InitiateCheckout', {
        content_name: this.boxData.title,
        content_ids: [this.boxData.id],
        content_type: 'product',
        value: this.boxData.salePriceWon,
        currency: 'KRW',
      });
      let date = new Date();
      let unixTimeMs = date.getTime();
      this.userBoxCreatedAt = Math.floor(unixTimeMs / 1000);
      let obj = {
        PCD_PAY_TYPE: "card",
        PCD_PAY_WORK: "CERT",
        PCD_CARD_VER: "02",
        PCD_PAY_OID: `${this.boxData.id}ORDER${this.userBoxCreatedAt}_${this.userBoxHistoryId}_${this.userId}`,
        PCD_PAYER_NO: this.userId,
        PCD_PAYER_NAME: this.user.userNickname,
        PCD_PAYER_HP: this.user.phoneNumber,
        PCD_PAYER_EMAIL: "",
        PCD_PAY_GOODS: this.boxData.title,
        PCD_PAY_TOTAL: this.boxData.salePriceWon,
        PCD_PAY_ISTAX: "Y",
        PCD_PAY_TAXTOTAL: "10",
        PCD_AUTH_KEY: this.authKey,
        PCD_RST_URL: 'https://api.meta-play.co.kr/my_tarot/web/payple_callback',
        PCD_PAY_URL: this.returnUrl,
      };
      window.PaypleCpayAuthCheck(obj);

      setTimeout(() => {
        this.isPaypleProcessing = false;
      }, 1000);
    },
    handleButtonClick() {
      this.openPopup();
      this.startSMSAuth();
    },
    logInputEvent(eventtype) {
      logEvent(`purchase_${eventtype}_click`, {});
    },
    openPopup(){
      this.popupActive = true;
    },
    closePopup(){
      this.popupActive = false;
    },
    openExitPopup(){
      this.isExitPopupActive = true;
    },
    closeExitPopup(){
      this.isExitPopupActive = false;
    },
    async reAuthPayple(){
      let payRes = await getPayplePartnerAuth();
      if (payRes.result === 'success'){
        this.authKey = payRes.data.auth_key;
        this.returnUrl = payRes.data.return_url;
      }
    },
    filterUserName(event) {
      // 한글, 영문, 숫자만 허용하는 정규표현식
      const regex = /^[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣]+$/;
      this.userName = event.target.value.split('').filter(char => regex.test(char)).join('');
    },

    preventInvalidInput(event) {
      // 한글, 영문, 숫자만 허용
      const regex = /^[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣]$/;
      if (!regex.test(event.key) && !this.isAllowedKey(event)) {
        event.preventDefault();
      }
    },

    isAllowedKey(event) {
      // 특정 기능 키들을 허용 (백스페이스, 삭제, 화살표 등)
      const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
      return allowedKeys.includes(event.key);
    },
  },
}
</script>

<style scoped>
.screen{
  position: relative;
  width: 100vw;
  height: 100dvh;
  overflow-y: scroll;
  max-width: 480px;
  margin: 0 auto;
  box-sizing: border-box;
}

.order-info{
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;

  text-align: left;
}

.wrapper {
  width: 100%;
}

.product-info{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 26px;
  border-bottom: 0.5px solid var(--Grayscale-Gray7);
}

.price-info{
  display: flex;
  flex-direction: row;
  padding-top: 26px;
  justify-content: space-between;
  align-items: center;
}

.prices{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}


.info-details{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 12px;
}

#product-thumbnail {
  width: 46px;
  height: 46px;
  object-fit: cover;
}

.original-price{
  font-size: 16px;
  font-weight: 500;
  color: var(--Grayscale-Gray6);
  text-decoration: line-through;
}

.user-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  box-sizing: border-box;
  padding: 24px;
}

.user-info-header {
  margin-bottom: 26px;
}

.input-label {
  font-size: 14px;
  font-weight: 800;
}

.text-input {
  width: 100%;
  height: 48px;
  padding: 13px 16px;
  margin-top: 8px;
  font-size: 20px;
  font-weight: 700;
  border: 2px solid var(--Grayscale-Gray10);
  border-radius: 8px;
  box-sizing: border-box;
}

.text-input::placeholder {
  color: var(--Grayscale-Gray8);
}

.user-email {
  margin-top: 24px;
}

.disclaimer-section {
  margin-top: 20px;
  padding: 14px 12px;
  background-color: var(--Grayscale-Gray10);
  border-radius: 4px;
}

.disclaimer {
  font-size: 12px;
  font-weight: 400;
  color: var(--Grayscale-Gray3);
}

.highlight {
  color: #FF6666;
}

.disclaimer-contact {
  margin-top: 7px;
  font-size: 12px;
  font-weight: 700;
  color: var(--Grayscale-Gray7);
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
.section {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 16px 24px;
  box-sizing: border-box;
}

.button-margin{
  width: 100vw;
  height: 120px;
  box-sizing: border-box;
}

input{
  width: 100%;
  height: 40px;
  padding: 10px 0;
  margin: 4px 0;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #B2B2B2;
}

input::placeholder {
  color: #B2B2B2;
  font-size: 20px;
  font-weight: 700;
}

.button {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 52px;
  background: #B2B2B2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  cursor: not-allowed;
}

.button.enabled {
  background: var(--Color-palette-Primary-Default); /* Example enabled color */
  cursor: pointer;
}

.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 90vw;
  padding: 16px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Grayscale-Gray2, #242424);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.20);
  color: var(--Grayscale-Gray12, #FFF);
  text-align: center;
  opacity: 0;
  z-index: 10000;
  transition: opacity 0.5s, transform 0.5s;
}
.toast.show {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s, transform 0.5s;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
