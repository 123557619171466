<template>
    <router-view/>
</template>

<script>
import { loadNaverCommonScript } from '@/tarot_box/helper/native_api.js';
import 'vue-loading-overlay/dist/css/index.css';


export default {
  name: 'App',
  data() {
    return {
      transitionName: 'slide-left',
    };
  },
  mounted() {
    loadNaverCommonScript();
  },
  watch: {
    $route(to, from) {
      this.transitionName = from.path.startsWith('/home') && to.path.startsWith('/tarot/detail') ? 'slide-left' : 'slide-right';
    }
  }
}
</script>

<style>
@import './styles/text_style.css';
@import './styles/colors.css';



:root {
  color-scheme: light only;
  /* vue-bottom-sheet 패딩 강제 */
  --bottom-sheet-padding: 0px;
}

@media (prefers-color-scheme: dark) {
  html, body {
    background: #fff !important;
    background-color: #fff !important;
  }
}
html,body{
  align-items: center;
  -webkit-backface-visibility:  hidden;
  -webkit-tap-highlight-color: var(--Color-palette-Primary-Lighten3);
  -webkit-touch-callout : none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  background-color: var(--Grayscale-Gray12);
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

button{
  -webkit-appearance: none; /* remove default appearance on Webkit-based browsers */
  -moz-appearance: none; /* remove default appearance on Mozilla-based browsers */
  appearance: none; /* remove default appearance on all other browsers */
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust : none;  /* 크롬, 사파리, 오페라 신버전 */
  -ms-text-size-adjust : none;  /* IE */
  -moz-text-size-adjust : none;  /* 파이어폭스 */
  -o-text-size-adjust : none;  /* 오페라 구버전 */
  font-family: inherit;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}


#app {
  font-family: "SUIT Variable", sans-serif;
  -webkit-text-size-adjust:none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--Grayscale-Gray1);
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  overflow-x: hidden;
  overscroll-behavior-y: none;
}


input {
  font-family: inherit;
  outline:none;
  border:none;
  resize: none;
  background: none;
  font-size:inherit;
  font-weight:inherit;
  color:inherit;
  line-height: inherit;
  border-radius: 0;
}

textarea {
  font-family: inherit;
  outline:none;
  border:none;
  resize: none;
  background: none;
}

.flicking-camera{
  display: flex;
}

*::-webkit-scrollbar {
  display: none;
}

:root {
  --vh: 1vh;
}
</style>
