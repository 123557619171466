<template>
  <div class="multi-choice-grid" v-safe-area-bottom>
    <div class="button-container">
      <button
          v-wave
          v-for="option in options"
          :key="option.value"
          @click="submitSelection(option)"
          :class="['multi-button', { selected: option.value === modelValue }]"
      >
        {{ option.value }}
        <small v-if="option.label">{{ option.label }}</small>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiChoiceGrid',
  props: {
    options: Array,
    title: String,
    subTitle: String,
    modelValue: [String, Number]
  },
  methods: {
    submitSelection(option) {
      this.$emit('update:modelValue', option.value);
      setTimeout(() => this.$emit('select', option.value), 200);
    }
  },
  emits: ['select', 'update:modelValue']
}
</script>

<style scoped>
.multi-choice-grid {
  margin-top: 56px;
  padding: 20px;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.multi-button {
  flex-basis: calc(50% - 5px);
  padding: 16px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  background: var(--Grayscale-Gray10, #ECECEC);
  color: var(--Grayscale-Gray3, #333);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "SUIT-Variable", sans-serif;
}

.multi-button.selected {
  background: var(--Grayscale-Gray2, #242424);
  color: white;
}

.multi-button small {
  font-family: "SUIT-Variable", sans-serif;
  font-size: 12px;
  color: var(--Grayscale-Gray5);
}
</style>
