<template>
  <div class="modal" :class="{ 'is-active': isActive }" v-if="isActive">
    <div class="overlay"></div>
    <div class="delete-popup-container" ref="popupContainer">
      <div v-if="requestSuccessful" class="popup-header">
        <span class="title2" style="margin-bottom: 12px;">
          Question submitted!
        </span>
        <span class="text2" style="margin-bottom: 40px;">
          We're working on your answer<br>
          and will email you when it's ready.<br>
          Feel free to go about your other tasks.
        </span>
      </div>
      <div v-else class="popup-header">
        <span class="title2" style="margin-bottom: 12px;">
          Personalized answers are<br>available only once.
        </span>
        <span class="text2" style="margin-bottom: 40px;">
          Once the interpretation begins,<br>
          <span style="color: var(--Color-palette-System-color-Red)">
             <strong>you won't be able to modify your question.</strong>
          </span>
          <br> Would you like to proceed<br>with the current question?
        </span>
      </div>
      <div v-if="requestSuccessful" class="popup-footer">
        <div class="btn" @click="handleCancel">
          확인
        </div>
      </div>
      <div v-else class="popup-footer">
        <div class="btn gray" @click="handleCancel">
          Cancel
        </div>
        <div class="btn" @click="submitextraRequest">
          Get the Answer
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DialogPopup",
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    requestSuccessful: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close','request'],
  mounted() {
    window.onBackButtonClick = null;
    window.onBackButtonClick = this.handleButtonClick;
  },
  beforeUnmount() {
    window.onBackButtonClick = null;
  },
  methods: {
    handleButtonClick() {
      if(this.isActive === true) {
        this.backgroundClick();
        return 'back';
      } else {
        return 'end';
      }
    },
    handleCancel() {
      this.closeConfirmPopupCallback();
    },
    async closeConfirmPopupCallback() {
      this.$emit('close');
    },
    submitextraRequest() {
      this.$emit('request');
    }
  }
}
</script>

<style>
.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.delete-popup-container {
  position: fixed;
  top: 50%; /* 화면의 수직 중앙에 위치하도록 설정 */
  left: 50%; /* 화면의 수평 중앙에 위치하도록 설정 */
  transform: translate(-50%, -50%); /* 팝업의 중심을 화면 중심에 정확히 맞추기 위해 */
  width: calc( 100vw - 40px);
  padding: 52px 0 16px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
}

.popup-header{
  display: flex;
  flex-direction: column;
}

.popup-footer{
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 0 16px;
  justify-content: space-between;
}

.btn{
  flex: 1;
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
  background-color: var(--Grayscale-Gray3);
  color: var(--Grayscale-Gray12);
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
}

.gray{
  background-color: var(--Grayscale-Gray9);
  color: var(--Grayscale-Gray3);
}
</style>