<template>
  <div class="component-container" :style="{ paddingTop: padding}">
    <img id="symbol" :src="tarotBoxHistory.output_page_meta_data.objet_icon_image_url" alt="symbol"/>
    <div class="heading-texts">
      <span class="font14 gray5"><strong>Chapter {{ currentChapterIndex }}</strong></span>
      <span class="serif gray1 font20">{{ currentPageData.page_title }}</span>
    </div>
    <div class="profile-table">
      <div class="name-table font14">
        {{ currentPageData.page_content.partner_name ? currentPageData.page_content.partner_name : userName }}님
      </div>
      <div class="info-table">
        <div>{{currentPageData.page_content.birth_date}}</div>
        <img src="../../assets/detail-page/table_crossline.svg" alt="|"/>
        <div>{{ currentPageData.page_content.saju_hour }}({{ currentPageData.page_content.saju_hour_hanja }})</div>
        <img src="../../assets/detail-page/table_crossline.svg" alt="|"/>
        <div>{{ currentPageData.page_content.gender }}</div>
      </div>
    </div>
    <div class="planetary-table">
      <div class="title3 gray3" style="margin-bottom: 16px;">{{ defineYours }} Chart</div>
      <div v-if="isPlanetaryDataLoading">
        <div v-for="i in 10" :key="i" class="table-row skeleton">
          <div class="sign-column">
            <div class="skeleton-box sign-skeleton"></div>
          </div>
          <div class="name-column">
            <div v-for="j in getRandomItemCount()" :key="j" class="name-item">
              <div class="skeleton-box symbol-skeleton"></div>
              <div class="skeleton-box name-skeleton"></div>
            </div>
          </div>
          <div class="house-column">
            <div class="skeleton-box house-skeleton"></div>
          </div>
        </div>
      </div>
      <div v-else-if="isPlanetaryDataLoaded">
        <div v-for="(group, house) in groupedCharts" :key="house" class="table-row">
          <div class="sign-column">
            <div class="text1 gray3">{{ group[0].sign }}</div>
          </div>
          <div class="name-column">
            <div v-for="item in group" :key="item.name" class="name-item">
              <NatalChartSymbols :sign="item.name"/>
              <div class="text1">{{ capitalizeAllLetters(item.name) }}</div>
            </div>
          </div>
          <div class="house-column title4">{{ house }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logEvent } from "@/tarot_box/helper/native_api";
import NatalChartSymbols from "@/tarot_box/pages/box_input_v2/assets/NatalChartSymbols.vue";

export default {
  name: "PlanetaryInfo",
  components: {
    NatalChartSymbols
  },
  props: {
    currentIndex: {
      type: Number,
    },
    currentPageData: {
      type: Object,
    },
    backgroundColor: {
      type: String,
    },
    tarotBoxHistory: {
      type: Object,
    },
    currentChapterIndex: {
      type: Number,
    },
    userName: {
      type: String,
    },
    isShare: {
      type: Boolean,
    },
    planetaryPositionData: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.$nextTick(() => {
      logEvent('result_planetary_into', {})
    });
  },
  computed: {
    padding() {
      return this.isShare ? '48px' : '100px';
    },
    isPlanetaryDataLoading() {
      return !this.planetaryPositionData || Object.keys(this.planetaryPositionData).length === 0;
    },
    isPlanetaryDataLoaded() {
      return this.planetaryPositionData && Object.keys(this.planetaryPositionData).length > 0;
    },
    groupedCharts() {
      if (!this.isPlanetaryDataLoaded) return {};
      return this.planetaryPositionData.charts.reduce((acc, item) => {
        if (!acc[item.house]) {
          acc[item.house] = [];
        }
        acc[item.house].push(item);
        return acc;
      }, {});
    },
    defineYours() {
      return this.currentPageData.page_content.partner_name ? "Partner's" : "Your";
    }
  },
  methods: {
    getRandomItemCount() {
      return Math.floor(Math.random() * 3) + 1;
    },
    capitalizeAllLetters(str) {
      return str.toUpperCase();
    },
  }
}
</script>

<style scoped>
/* ... (keep existing styles) ... */

.planetary-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0 20px 0;
  box-sizing: border-box;
  min-height: 300px;
}

.table-row {
  display: flex;
  border-top: 1px solid var(--Grayscale-Gray3);
  padding: 0;
}

.table-row:last-child {
  border-bottom: 1px solid var(--Grayscale-Gray3);
}

.sign-column, .name-column, .house-column {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 12px;
  border-right: 1px solid var(--Grayscale-Gray3);
}

.house-column {
  border-right: none;
}

.sign-column .text1 {
  transform: translateY(15px);
}

.sign-column {
  flex: 3;
  justify-content: flex-start;
}

.name-column {
  flex: 5;
  flex-direction: column;
}

.house-column {
  flex: 1;
  align-items: center;
}

.name-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  color: var(--Grayscale-Gray5);
  padding: 13px 0;
}

/* Skeleton loading styles */
.table-row.skeleton {
  animation: skeleton-loading 1.5s infinite;
}

.skeleton-box {
  background: #e0e0e0;
  border-radius: 4px;
}

.sign-skeleton {
  width: 60%;
  height: 20px;
  transform: translateY(15px);
}

.symbol-skeleton {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.name-skeleton {
  width: 60%;
  height: 20px;
}

.house-skeleton {
  width: 30px;
  height: 20px;
}

@keyframes skeleton-loading {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
</style>