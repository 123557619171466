<template>
  <svg viewBox="0 0 333 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :class="fillClass" d="M0.113249 3L3 5.88675L5.88675 3L3 0.113249L0.113249 3ZM332.887 3L330 0.113249L327.113 3L330 5.88675L332.887 3ZM3 3.5H330V2.5H3V3.5Z"/>
  </svg>
</template>

<script>
export default {
  name: "TarotLine",
  props: {
    fillClass: {
      type: String,
      default: "svg-fill-gray7"
    }
  }
}
</script>

<style scoped>
</style>