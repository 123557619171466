import {camelToSnakeCase} from "@/common/utils";

class AppCommander {
    callNativeMethod(info) {
        if (typeof window.appInterface !== 'undefined' && typeof window.appInterface.postMessage === "function") {
            window.appInterface.postMessage(info);
        }
    }

    callAppAction(action, data = {}) {
        const payload = camelToSnakeCase({ action, data });
        window.callNativeMethod(JSON.stringify(payload));
    }
}

const appCommander = new AppCommander();
const AppCommanderPlugin = {
    install: (app) => {
        window.appCommander = appCommander;
        app.provide('appCommander', appCommander);
    }
}

export default AppCommanderPlugin;
