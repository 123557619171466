<template>
  <div class="confirm-popup-back-page">
    <div class="confirm-popup">
      <div class="confirm-popup-contents">
        <div class="confirm-popup-contents-title">
          사주명식 계산안내
        </div>
        <div class="confirm-popup-contents-delete">
          마이타로 콘텐츠 해석에 사용되는 만세력은 <strong>입력하신 양력 생년월일시를 음력으로 변환하고 써머타임 보정 및 1920년 이후의 절기의 시작일을 데이터화</strong>한 내용을 바탕으로 사주명식을 계산하고 있습니다.<br><br>알고계시는 사주명식과 다르게 계산되어 나오는 것은 <strong style="color:#4975E5;">마이타로 만세력의 독자적인 해석 방식</strong> 때문이며 고객님께서 잘못 입력하시거나 <strong style="color:#4975E5;">틀린 해석이 출력되는 것은 아닙니다.</strong>
        </div>
      </div>
      <div class="confirm-popup-button-area">
        <div class="confirm-popup-button" v-wave @click="$emit('close-popup')">
          닫기
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
  },
  emits: ['close-popup'],
  props: {
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style scoped>
.confirm-popup-back-page{
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.80);
}

.confirm-popup{
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 40px;
  width: 90%;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  box-sizing: border-box;

  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
  z-index: 1001;
}

.confirm-popup-contents{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  gap: 16px;
}

.confirm-popup-contents-title{
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.confirm-popup-contents-delete {
  display: block;
  text-align: left;
  line-height: 140%;
}

.confirm-popup-button-area {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 9px;
}

.confirm-popup-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  background: #1E2352;
  color: #FFF;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}
</style>