import {api} from "@/api/api";

export function searchCities(query, lang) {
    return api.get(
        `/my_tarot/search_location`,
        { params: { query: query, lang: lang } }
    );
}

export function getCityCoord(placeId) {
    return api.get(
        `/my_tarot/location_coord`,
        { params: { place_id: placeId } }
    );
}

export function calculateTimeDifferenceFromLongitudeInMinute(longitude) {
    const longitudeDiff = longitude - 135;
    return Math.floor(longitudeDiff * 60 * 24 / 360.0);
}
