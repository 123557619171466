<template>
  <div class="modal">
    <div class="overlay" @click="backgroundClick"></div>
    <div class="delete-popup-container" ref="popupContainer">
      <div class="popup-header">
        <span class="title2" style="margin-bottom: 12px;">
          마이타로를 종료할까요?
        </span>
        <span class="text2" style="margin-bottom: 40px;">
          고민이 생기면 언제든 말해주세요!
        </span>
      </div>
      <div class="popup-content">
      </div>
      <div class="popup-footer">
        <div class="btn" @click="handleCancel" style="background-color: #c7c7c7">
          취소
        </div>
        <div class="btn" @click="exitApp">
          종료하기
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ProcessingPopup",
  mounted() {
    window.onBackButtonClick = null;
    window.onBackButtonClick = this.handleButtonClick;
  },
  beforeUnmount() {
    window.onBackButtonClick = null;
  },
  methods: {
    handleButtonClick() {
      this.backgroundClick();
      return 'back';
    },
    backgroundClick() {
      this.$emit('close');
    },
    handleCancel() {
      this.$emit('close');
    },
    exitApp() {
      window.callNativeMethod(`{"action": "exit_app"}`);
    },
  }
}
</script>

<style scoped>
.title2, .text2{
  font-family: "SUIT-Variable", sans-serif;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.delete-popup-container {
  position: fixed;
  top: 50%; /* 화면의 수직 중앙에 위치하도록 설정 */
  left: 50%; /* 화면의 수평 중앙에 위치하도록 설정 */
  transform: translate(-50%, -50%); /* 팝업의 중심을 화면 중심에 정확히 맞추기 위해 */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc( 100vw - 40px);
  padding: 52px 0 30px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
}

.popup-header{
  display: flex;
  flex-direction: column;
}

.popup-footer{
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 0 16px;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
}

.btn{
  flex: 1;
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
  background-color: var(--Color-palette-Primary-Default);
  color: var(--Grayscale-Gray12);
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
}
</style>