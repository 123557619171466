<template>
  <div class="section-header" :key="currentIndex">
    <span class="title5">STEP {{ currentIndex }}</span>
    <span class="title2">{{sectionTitle}}</span>

    <span v-if="getSectionDescription() !== ''" class="title3" >
      {{ getSectionDescription() }}
    </span>
  </div>
</template>

<script>
export default {
  name: "InputHeader.vue",
  props: {
    inputSections: {
      type: Array,
    },
    currentIndex: {
      type: Number,
    },
    userName: {
      type: String,
    },
    isChecker: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sectionTitle() {
      const sectionIndex = this.currentIndex - 1;
      const sectionType = this.inputSections[sectionIndex]?.sectionType;
      const sectionTitle = this.inputSections[sectionIndex]?.sectionTitle;

      if(this.isChecker) {
        switch (sectionType) {
          case 'personalInfo':
            return `Confirm Your Information`;
          case 'partnerInfo':
            return `Confirm Partner Information`;
          case 'additionalInfo':
            return `Confirm Additional Information`;
          default:
            return `Confirm Additional Information`;
        }
      } else{
        switch (sectionType) {
          case 'personalInfo':
            return `Enter Your Information`;
          case 'partnerInfo':
            return `Enter Partner Information`;
          case 'additionalInfo':
            return `Provide More Information`;
          default:
            return sectionTitle;
        }
      }
    },
  },
  methods : {
    getSectionDescription() {
      const sectionIndex = this.currentIndex - 1;
      const sectionType = this.inputSections[sectionIndex]?.sectionType;
      const sectionDescription = this.inputSections[sectionIndex]?.sectionDescription;

      if(['additionalInfo', 'personalInfo', 'partnerInfo'].includes(sectionType)) {
        return ''; // 또는 다른 적절한 기본값
      }
      return sectionDescription || ''; //
    }
  }
}
</script>

<style scoped>
.section-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 42px 0 0 0;
  line-height: 120%;
  animation: fadeInDown 0.5s ease-out;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.title2 {
  font-size: 24px;
  margin-top: 8px;
  margin-bottom: 28px;
  word-break: keep-all;
}

.title3 {
}
</style>